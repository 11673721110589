.hotspots-entries {
    // Vars

    // Support

    // Module
    & {
        width: 100%;

        .hotspots-entries--entry {
            border-bottom: 1px solid $c--monochrome-cool-gray;

            &:first-child {
                border-top: 1px solid $c--monochrome-cool-gray;
            }
        }

        .hotspots-entries--button {
            @include type--hotspot();
            @include ff--base-semi-bold;
            cursor: pointer;
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            padding: 12px 0;
            width: 100%;
            text-align: left;
        }

        .hotspots-entries--title {
            flex: 1 1 auto;
            transition: color $trs--base;
        }

        .hotspots-entries--icon {
            $sz--icon: 24px;
            $sz--bar-width: 14px;
            $sz--bar-height: 2px;

            position: relative;
            display: block;
            align-self: center;
            flex: 0 0 $sz--icon;
            height: 0;

            &::after,
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                background-color: currentColor;
            }

            &::before {
                width: $sz--bar-width;
                height: $sz--bar-height;
                transform: translate(-50%, -50%);
            }

            &::after {
                width: $sz--bar-height;
                height: $sz--bar-width;
                transform: translate(-50%, -50%) rotateX(0);
                opacity: 1;
                transition: $trs--common-props;
            }
        }

        .hotspots-entries--accordion-content {
            @include type--hotspot();
            padding-bottom: 12px;
        }
    }

    // Facets
    & {
        // Product facets
        @each $series, $series-color in $products {
            &.hotspots-entries__product-#{$series} {
                .hotspots-entries--label {
                    color: $series-color;
                }

                .hotspots-entries--entry {
                    &.hotspots-entries--entry__is-active {
                        .hotspots-entries--title {
                            color: $series-color;
                        }
                    }
                }

                .hotspots-entries--icon {
                    color: $series-color;
                }
            }
        }
    }

    // States
    & {
        // Hotspot entry is active
        .hotspots-entries--entry {
            &.hotspots-entries--entry__is-active {
                .hotspots-entries--icon::after {
                    transform: translate(-50%, -50%) rotateX(90deg);
                    opacity: 0;
                }
            }
        }
    }
}

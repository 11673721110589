&.team-list-member__small {
    .team-list-member--image-col {
        @include grid--span((ratio: 5/6));
    }

    .team-list-member--info-col {
        @include grid--span((ratio: 4/6));
    }
}

&.team-list-member__small.team-list-member__odd {
    &.team-list-member__right {
        .team-list-member--image-col {
            @include grid--span((ratio: 5/6));
        }

        .team-list-member--info-col {
            @include grid--span((ratio: 4/6));
        }
    }

    .team-list-member--image-col {
        @include grid--span((ratio: 6/6));
    }

    .team-list-member--info-col {
        @include grid--span((ratio: 5/6));
    }
}

.menu-item {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .menu-item--popover {
            font-size: 0;
        }

        .menu-item--accordion {
            width: 100%;
        }

        .menu-item--accordion-content {
            padding: 0 16px 48px;
        }

        .menu-item--toggle {
            display: block;
            cursor: pointer;
            text-align: center;
            color: $c--label-link;
            line-height: 1;

            &.u-router-link-exact-active {
                cursor: default;
            }

            & {
                .menu-item--text {
                    @include type--copy-small();
                    @include link--highlight();
                    transition: $trs--link-highlight-props;
                }
            }

            @include has-focus(true) {
                .menu-item--text {
                    @include link--highlight-half-active();
                    color: $c--brand-gerteis-dark-blue-darker;
                }
            }

            @include has-focus(default) {
                &.menu-item--toggle__is-active {
                    .menu-item--text {
                        @include link--highlight-active();
                        color: $c--brand-gerteis-dark-blue-darker;
                    }
                }
            }
        }

        .menu-item--text {
            display: inline;
            padding: 0 2px;
            font-size: $fz--small;
            line-height: $lh--small;

            @include mq('<=lg') {
                font-size: $fz--x-small;
                line-height: $lh--x-small;
            }
        }
    }

    // Facets
    & {
        // Within popover
        &.menu-item__child {
            &:first-child {
                padding-top: 8px;
            }

            &:last-child {
                padding-bottom: 8px;
            }

            .menu-item--toggle {
                width: 100%;
                padding: 8px 0;
                margin: 0 16px;
                text-align: left;
            }
        }

        // Within a `menu-group` component
        &.menu-item__in-group {
            .menu-item--toggle.menu-item--toggle {
                justify-content: flex-start;
                padding: 8px 0;
            }
        }

        // Within `menu-item-product` or `language-nav`
        &.menu-item__product,
        &.menu-item__language {
            $sz--icon: map-get(node-sass--get-icon-sizes(), 'medium');

            .menu-item--icon {
                position: relative;
                width: $sz--icon;
                height: $sz--icon;
                margin-right: 8px;
            }

            .menu-item--toggle {
                display: inline-flex;
            }
        }

        // Within `menu-item-product`
        &.menu-item__product {
            @each $product-color-key, $product-color-value in $products {
                &.menu-item__product-#{$product-color-key} {
                    .menu-item--icon-part.menu-item--icon-part__ribbon {
                        color: $product-color-value;
                    }
                }
            }

            .menu-item--icon-part {
                @include overlay();
            }
        }

        // Language
        &.menu-item__language {
            .menu-item--toggle {
                padding: 9px 0;
            }

            .menu-item--text {
                text-transform: uppercase;
            }
        }

        // In `lang-nav`
        &.menu-item__lang-nav {
            .menu-item--toggle {
                @include mq('>md') {
                    padding: 4px 8px;
                }
            }
        }

        // In `lang-nav` in `footer-bottom-row`
        &.menu-item__lang-nav.menu-item__footer-bottom-row {
            .menu-item--text {
                @include type--copy-x-small();
            }
        }

        // In mobile nav
        &.menu-item__mobile-nav {
            .menu-item--toggle {
                width: 100%;
                padding: 12px 16px;
                text-align: left;
            }

            .menu-item--text {
                @include type--copy-medium();
            }
        }

        // In accordion
        &.menu-item__accordion {
            margin-left: 32px;
            border-bottom: 1px solid $c--monochrome-light-gray;

            &:first-child {
                border-top: 1px solid $c--monochrome-light-gray;
            }

            .menu-item--toggle {
                padding-left: 0;
                padding-right: 0;
            }
        }

        // In accordion and as a product
        &.menu-item__accordion.menu-item__product {
            padding: 8px 0;
        }
    }

    // States
}

.product-list {
    // Vars

    // Support

    // Module
    & {
        & {
            background-color: $c--monochrome-warm-gray;
        }

        .product-list--row {
            justify-content: center;
        }

        .product-list--col {
            @include grid--span((ratio: 12/12));
        }
    }

    // Facets

    // States
}

.product-serie {
    // Vars

    // Support

    // Module
    & {
        & {
            margin: 48px 0;

            @include grid--root();
            @include grid--space();
        }

        .product-serie--name {
            margin-bottom: 16px;
        }

        .product-serie--row {
            justify-content: center;
        }

        .product-serie--products-row {
            @include mq('<=lg') {
                justify-content: flex-start;
            }
        }

        .product-serie--col {
            @include grid--span((ratio: 10/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }

        .product-serie--lead {
            font-size: $fz--medium;
            color: $c--monochrome-steel-gray;
            margin-bottom: 12px;
            max-width: 780px;

            @include type--h4();
        }

        .product-serie--item {
            @include grid--span((ratio: 10/12, mq: '>lg'));
            @include grid--span((ratio: 4/12, mq: 'lg'));
            @include grid--span((ratio: 6/12, mq: '<=md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }
    }

    // Facets

    // States
}

////
/// Font inclusion and support library
////

/// Font import
///
@import url('https://use.typekit.net/mlu0ksz.css');


/// Dependencies and components
///
@import 'font__vars';
@import 'font__helpers';
@import 'font__definitions';

/// Font-face definitions
///
@include _font--create() {
    @include ff--base-regular($ff--base);
}

@include _font--create() {
    @include ff--base-italic($ff--base);
}

@include _font--create() {
    @include ff--base-semi-bold($ff--base);
}

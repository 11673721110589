.document-checkbox-item {
    // Vars

    // Support

    // Module
    & {
        position: relative;

        .document-checkbox-item--label {
            position: relative;
            width: 100%;
        }

        .document-checkbox-item--image {
            @include shadow('level-1');
            display: block;
            width: 100%;
            margin-bottom: 4px;
        }

        .document-checkbox-item--content {
            width: 100%;
        }

        .document-checkbox-item--text {
            @include type--copy-small;
            color: $c--monochrome-steel-gray;
        }
    }

    // Facets

    // States
}

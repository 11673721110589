&.grid__full {
    // Vars
    $scrollbar-offset: 16px;
    $grid--full-container-sizes: $grid--base-container-sizes;
    $grid--full-spacing: (
        (mq: 'xs', gutter: (16px 16px), edge: 16px),
        (mq: 'sm', gutter: (20px 20px), edge: 20px),
        (mq: 'md', gutter: (24px 24px), edge: 24px),
        (mq: 'lg', gutter: (32px 32px), edge: 32px),
        (mq: 'xl', gutter: (36px 36px), edge: 36px),
        (mq: 'xxl', gutter: (40px 40px), edge: 40px),
    ) !global;

    // Support

    // Module
    & {
        @include grid--root();

        @each $value in $grid--full-container-sizes {
            @include grid--constrain($value);
        }

        @each $value in $grid--full-spacing {
            @include grid--space($value);
        }

        .grid--container.grid--container__fluid {
            max-width: none;
        }

        .grid--container.grid--container__allow-overflow {
            overflow: visible;
        }
    }

    // Facets

    // States
}

.mobile-nav {
    // Vars

    // Support

    // Module
    & {
        & {
            z-index: z-index('mobilenav');
            width: 100%;
            height: $sz--mobile-nav-height;
            background-color: $c--monochrome-white;
        }

        .mobile-nav--root {
            z-index: z-index('mobilenav');
            //position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            background-color: $c--monochrome-white;
            border-bottom: $border-style;
            transition: border-bottom-color $trs--base;
        }

        .mobile-nav--bar {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: stretch;
            height: $sz--mobile-nav-height;
        }

        .mobile-nav--home {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            flex: 1 0 auto;
            padding: 0 16px;

            transition: $trs--base opacity;
        }

        .mobile-nav--menu {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: stretch;
            height: $sz--mobile-nav-height;
        }

        .mobile-nav--menu-btn {
            padding: 0 20px;
            color: $c--brand-gerteis-dark-blue;
        }
    }

    // Facets
    & {
        &.mobile-nav__is-open {
            .mobile-nav--root {
                border-bottom-color: transparent;
            }
        }
    }

    // States
}

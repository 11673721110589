.branch-detail-country-list {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 6px 0;
            line-height: $lh--medium;
        }

        .branch-detail-country-list--list.branch-detail-country-list--list {
            padding-left: 18px;
        }

        .branch-detail-country-list--item.branch-detail-country-list--item {
            margin-top: 0;
        }
    }

    // Facets

    // States
}

/// Monochrome special decoration colors
///
$monochromes: (
    'white': #ffffff,
    'black': #000000,
    'warm-gray': #f8f8f8,
    'light-gray': #f2f2f2,
    'cool-gray': #b2bac4,
    'steel-gray': #84898f,
    'slate-gray': #686c71,
    'dark-gray': #3d3d3d,
    'black-gray': #222222,
);

/// Brand specific colors
///
$brands: (
    'gerteis-dark-blue': #00519e,
    'gerteis-dark-blue-darker': #002a6f,
    'gerteis-dark-blue-lighter': #4f7dd0,
    'gerteis-light-blue': #00b1e6,
    'gerteis-light-blue-darker': #0081b4,
    'gerteis-light-blue-lighter': #64e3ff,
);

/// Product specific colors
///
$products: (
    'mini-polygran': #bc7429,
    'polygran': #f68000,
    'micro-pactor': #bed73b,
    'mini-pactor': #56ac42,
    'macro-pactor': #c73188,
    'macro-pactor-typ-c': #c73188,
    'ultra-pactor': #e42120,
);

/// Single purpose colors
///
$labels: (
    'text': map-get($monochromes, 'black-gray'),
    'link': map-get($brands, 'gerteis-dark-blue'),
    'selection': null,
);

/// Signal colors
///
$signals: (
    'info': map-get($brands, 'gerteis-blue'),
    'error': #e20d0d,
    'warning': #ffff00,
    'success': #2dcc70,
);

/// Main color map
///
$color-maps: (
    monochrome: $monochromes,
    brand: $brands,
    product: $products,
    label: $labels,
    signal: $signals,
);

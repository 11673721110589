.item-list {
    // Vars

    // Support

    // Module
    & {
        background-color: $c--monochrome-warm-gray;
        padding: 24px 0;

        .item-list--row {
            justify-content: center;
            margin: 12px 0;
        }

        .item-list--col {
            @include grid--span((ratio: 10/12));
        }
    }

    // Facets

    // States
}

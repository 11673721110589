.job-image {
    // Vars

    // Support

    // Module
    & {
        .job-image--image {
            display: none;
            width: 100%;

            @include mq('>sm') {
                display: block;
            }
        }

        .job-image--image__mobile {
            display: none;

            @include mq('<=sm') {
                display: block;
            }
        }
    }

    // Facets

    // States
}

////
/// Button facets and state generation library
////

/// Dependencies and components
///
@import 'btn__vars';
@import 'btn__helpers';
@import 'btn__facets';


/// Apply button styles to their respective selectors
/// @param {string} $type - A button-facet name defined in `$button-facets`
///
@mixin btn--style($type) {
    // Not disabled
    &:not(:disabled):not([data-disabled]):not(.u-router-link-exact-active) {
        // Normal
        & {
            @include has-focus(false) {
                &, &::after {
                    @include _btn--apply-style($type, 'base');
                    text-decoration: none;
                }
            }
        }

        // Not inert
        &:not([data-inert]) {
            // Focus
            @include has-focus(true) {
                &, &::after {
                    @include _btn--apply-style($type, 'focus');
                    text-decoration: none;
                }
            }

            // Active
            &:active,
            &[data-active] {
                @include has-focus(default) {
                    &, &::after {
                        @include _btn--apply-style($type, 'active');
                    }
                }
            }
        }
    }

    // Disabled
    &:disabled,
    &[data-disabled],
    &.u-router-link-exact-active {
        &, &::after {
            @include _btn--apply-style($type, 'disabled');
            text-decoration: none;
            cursor: default;
        }
    }

    // Inert
    &[data-inert] {
        cursor: default;
    }
}

/// All applicable btn facets, sizes and special types
///
.btn {
    // Vars

    // Support

    // Module
    & {
        @include btn--base();
    }

    // Facets
    & {
        // Sizes
        &.btn__medium {
            @include btn--size-medium();
        }

        &.btn__small {
            @include btn--size-small();
        }

        // Facets
        &.btn__primary {
            @include btn--primary();
        }

        &.btn__secondary {
            @include btn--secondary();
        }

        // Special
        &.btn__has-grid {
            @include btn--has-grid();
        }

        &.btn__bubble {
            @include btn--bubble();
        }
    }

    // States
    & {
        @include _btn--define-facets();
    }
}

.hotspots-display {
    // Vars
    $sz--hotspot-large: 32px;
    $sz--hotspot-small: 21px;

    // Support

    // Module
    & {
        .hotspots-display--hotspot {
            position: absolute;
            width: $sz--hotspot-large;
            height: $sz--hotspot-large;
            transform: translate(-50%, -50%);
            overflow: hidden;

            @include mq('<=md') {
                width: $sz--hotspot-small;
                height: $sz--hotspot-small;
            }
        }

        .hotspots-display--button {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            transition: $trs--common-props;
            border: 2px solid transparent;
            border-radius: $sz--hotspot-large;
            cursor: pointer;

            @include mq('<=md') {
                border-radius: $sz--hotspot-small;
            }
        }

        .hotspots-display--label {
            @include type--hotspot();
            @include ff--base-semi-bold;
            position: relative;
            transform: translateY(-0.5px) rotateZ(1turn);
        }
    }

    // Facets
    & {
        // Product facets
        @each $series, $series-color in $products {
            &.hotspots-display__product-#{$series} {
                .hotspots-display--button {
                    background-color: rgba($c--monochrome-white, 0.8);
                    border-color: $series-color;
                    color: $series-color;
                }

                .hotspots-display--hotspot {
                    &.hotspots-display--hotspot__is-active {
                        .hotspots-display--button {
                            background-color: $series-color;
                            color: $c--monochrome-white;
                        }
                    }
                }
            }
        }
    }

    // States
    & {
        // Hotspot is active
        .hotspots-display--hotspot {
            &.hotspots-display--hotspot__is-active {
                .hotspots-display--button {
                    cursor: auto;
                }
            }
        }
    }
}

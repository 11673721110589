.modal {
    // Vars

    // Support

    // Module
    & {
        position: relative;

        .modal--close-wrap {
            position: absolute;
            top: 0;
        }

        .modal--close {
            z-index: 3;
            position: fixed;
        }

        .modal--close-button {
            display: block;
            padding: 10px;
        }
    }

    // Facets
    & {
        // Full width modal overlay
        &.modal__base {
            width: 100%;

            .modal--close-wrap {
                right: 0;
            }

            .modal--close {
                transform: translateX(-100%);
            }
        }

        // Hide close button
        &.modal__mobile-nav {
            width: 100%;

            .modal--close-wrap {
                display: none;
            }
        }

        // Mobile nav
        &.modal__mobile-nav {
            width: 100%;

            .modal--close-wrap {
                display: none;
            }

            &,
            .modal--body {
                display: flex;
                flex-flow: row nowrap;
                align-items: stretch;
                width: 100%;
            }
        }
    }

    // States
}

.branch-details {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 24px 0;
            overflow: hidden;
        }

        .branch-details--address-col {
            @include grid--span((ratio: 6/10));

            @include mq('<=md') {
                @include grid--span((ratio: 10/10));
            }
        }

        .branch-details--map-row {
            justify-content: center;
            margin: 24px 0;
        }

        .branch-details--map-col {
            @include grid--span((ratio: 10/10));
        }

        .branch-details--representations-col {
            @include grid--span((ratio: 4/10));

            @include mq('<=md') {
                @include grid--span((ratio: 10/10));
            }
        }

        .branch-details--address {
            margin: 24px 0;
            color: $c--monochrome-steel-gray;

            @include type--copy-medium;
        }

        .branch-details--representations {
            margin: 8px 0;
            color: $c--monochrome-steel-gray;

            @include type--copy-medium;
        }

        .branch-details--representations-toggle {
            color: $c--brand-gerteis-dark-blue;
            cursor: pointer;

            @include link--highlight;

            &:hover {
                @include link--highlight-active;
            }
        }

        .branch-details--representations-toggle-icon {
            position: relative;
            top: 3px;
            display: inline-block;
        }

        .branch-details--phone {
            margin: 8px 0;
            color: $c--monochrome-steel-gray;

            @include type--copy-small;
        }

        .branch-details--phone-label {
            margin-right: 24px;
        }

        .branch-details--phone-number {
            @include type--copy-medium;
        }
    }

    // Facets

    // States
}

.collapsible {
    // Vars

    // Support

    // Module
    & {
        width: 100%;

        .collapsible--entries {
            margin-top: 24px;
        }

        .collapsible--entry {
            border-bottom: 1px solid $c--monochrome-cool-gray;

            &:first-child {
                border-top: 1px solid $c--monochrome-cool-gray;
            }
        }

        .collapsible--button {
            @include type--hotspot();
            @include ff--base-semi-bold;

            display: flex;
            justify-content: space-between;
            padding: 12px 0;
            width: 100%;
            cursor: pointer;
        }

        .collapsible--label {
            transition: color $trs--base;
        }

        .collapsible--icon {
            $sz--icon: 24px;
            $sz--bar-width: 14px;
            $sz--bar-height: 2px;

            position: relative;
            display: block;
            align-self: center;
            flex: 0 0 $sz--icon;
            height: 0;

            &::after,
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                background-color: currentColor;
            }

            &::before {
                width: $sz--bar-width;
                height: $sz--bar-height;
                transform: translate(-50%, -50%);
            }

            &::after {
                width: $sz--bar-height;
                height: $sz--bar-width;
                transform: translate(-50%, -50%) rotateX(0);
                opacity: 1;
                transition: $trs--common-props;
            }
        }

        .collapsible--accordion-content {
            @include type--hotspot();
            padding-bottom: 12px;
        }
    }

    // Facets

    // States
    & {
        // Hotspot entry is active
        .collapsible--entry {
            &.collapsible--entry__is-active {
                .collapsible--icon::after {
                    transform: translate(-50%, -50%) rotateX(90deg);
                    opacity: 0;
                }
            }
        }
    }
}

.job-card-content {
    // Vars

    // Support

    // Module
    & {
        padding: 48px 24px;

        @include mq('<=md') {
            padding: 24px;
        }

        .job-card-content--col {
            @include grid--span((ratio: 1/2));
            @include grid--span((ratio: 1/1, mq: '<=sm'));
        }

        .job-card-content--description {
            @include type--copy-medium();
            color: $c--monochrome-steel-gray;
        }
    }

    // Facets

    // States
}

/// Base mixin for buttons
///
@mixin btn--base {
    border: $sz--border-width-base solid transparent;
    border-radius: $sz--border-radius-base;
    cursor: pointer;
    transition: transition-props($trs--base, color, border-color, background-color, opacity);
}


/// Button sizes
///
@mixin btn--size-medium() {
    min-width: 168px;
    padding: 11px 16px 12px;
    font-size: $fz--medium;

    &.btn__bubble {
        padding: 8px;
    }
}

@mixin btn--size-small() {
    padding: 12px 20px 13px;
    font-size: $fz--small;
    line-height: 1;

    &.btn__bubble {
        padding: 8px;
    }
}


/// Button facets
///
@mixin btn--primary() {
    $sz--box-shadow-offset: 6px;
    transition: transition-props($trs--base, color, border-color, background-color, opacity, box-shadow);
    border-radius: 2px;

    &:not(:disabled):not([data-disabled]):not([data-inert]):not(.u-router-link-exact-active) {
        @include has-focus(true) {
            & {
                // Focus
                box-shadow:
                    0 ($sz--box-shadow-offset + 4) 20px 0 rgba(0, 0, 0, 0.19),
                    0 $sz--box-shadow-offset 6px 0 rgba(0, 0, 0, 0.26);
            }
        }

        &:active,
        &[data-active] {
            @include has-focus(default) {
                & {
                    // Active
                    box-shadow:
                        0 ($sz--box-shadow-offset + 4) 20px 0 rgba(0, 0, 0, 0),
                        0 $sz--box-shadow-offset 6px 0 rgba(0, 0, 0, 0);
                }
            }
        }
    }
}


@mixin btn--secondary() {
    $sz--box-shadow-offset: 6px;
    transition: transition-props($trs--base, color, border-color, background-color, opacity, box-shadow);
    border-width: 2px;
    border-radius: 2px;

    &:not(:disabled):not([data-disabled]):not([data-inert]):not(.u-router-link-exact-active) {
        @include has-focus(true) {
            & {
                // Focus
                box-shadow:
                    0 ($sz--box-shadow-offset + 4) 20px 0 rgba(0, 0, 0, 0.19),
                    0 $sz--box-shadow-offset 6px 0 rgba(0, 0, 0, 0.26);
            }
        }

        &:active,
        &[data-active] {
            @include has-focus(default) {
                & {
                    // Active
                    box-shadow:
                        0 ($sz--box-shadow-offset + 4) 20px 0 rgba(0, 0, 0, 0),
                        0 $sz--box-shadow-offset 6px 0 rgba(0, 0, 0, 0);
                }
            }
        }
    }
}


/// Special types
///
@mixin btn--has-grid() {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @include grid--root((
        row: 'wrap',
        cols: ('icon', 'text'),
    ));
    @include grid--space((
        gutter: 8px,
        row: 'wrap',
        cols: ('icon', 'text'),
    ));

    .btn--wrap {
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
    }

    .btn--text {
        // NOTE: Depending on the font, we need a correction value to visually center our text:
        position: relative;
        transform: translateY(-1px);
        text-align: left;
    }
}

@mixin btn--bubble() {
    min-width: 0;
    border-radius: 50%;

    .btn--icon {
        margin: 0;
    }
}

.product-card-header-image {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        width: 100%;
        height: 100%;
        border-right: $border-style;
        background-color: $c--monochrome-light-gray;

        .product-card-header-image--wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            @include mq('>md') {
                padding: 24px;
            }

            @include mq('<=md') {
                padding: 16px;
            }
        }

        .product-card-header-image--image {
            display: block;
            width: 100%;

            @include mq('>md') {
                width: 200px;
            }

            @include mq('<=md') {
                width: 160px;
            }
        }

        .product-card-header-image--logo {
            display: block;
            height: 16px;
        }
    }

    // Facets

    // States
}

.menu-group {
    // Vars

    // Support

    // Module
    & {
        padding: 16px;

        + .menu-group {
            padding-top: 0;
        }

        .menu-group--title {
            color: $c--monochrome-steel-gray;
            font-size: $fz--xx-small;
            line-height: $lh--xx-small;
        }
    }

    // Facets
    & {
        &.menu-group__accordion {
            padding: 0;

            ~ .menu-group {
                padding-top: 24px;
            }

            .menu-group--title {
                display: block;
                margin: 0 0 8px 32px;
            }
        }
    }

    // States
}

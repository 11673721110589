.product-hotspots-stage {
    // Vars
    $ratio: 720 / 1080;

    // Support

    // Module
    & {
        overflow: hidden;
        background-color: $c--monochrome-warm-gray;

        .product-hotspots-stage--description {
            color: $c--monochrome-steel-gray;
            margin-top: 24px;
            margin-bottom: 24px;

            @include mq('<=md') {
                display: none;
            }
        }

        .product-hotspots-stage--container.product-hotspots-stage--container {
            overflow: visible;
        }

        .product-hotspots-stage--row {
            justify-content: center;
            align-items: flex-start;
        }

        .product-hotspots-stage--logo {
            margin: 40px 0;
            width: 100%;
            height: 100%;
            max-width: 248px;
            max-height: 24px;
        }

        .product-hotspots-stage--col {
            @include grid--span((ratio: 1/1));
            display: flex;
            flex-flow: row;
            justify-content: center;

            &.product-hotspots-stage--col__description {
                z-index: 1;
            }

            &.product-hotspots-stage--col__rotator {
                @include is-selectable(false);
                @include grid--span((ratio: 3/4));

                @include mq('<=sm') {
                    @include grid--span((ratio: 1/1));
                }

                @include mq('md') {
                    @include grid--span((ratio: 8/10));
                }
            }

            &.product-hotspots-stage--col__list {
                @include grid--span((ratio: 1/4));

                @include mq('md') {
                    @include grid--span((ratio: 1/2));
                }

                @include mq('<=sm') {
                    @include grid--span((ratio: 1/1));
                }
            }
        }

        // This div is purely to fix a display bug for Edge
        .product-hotspots-stage--potato-rapper {
            width: 100%;
        }

        .product-hotspots-stage--rotator-wrapper {
            @include overlay(relative);
            width: 100%;
            padding-top: 100% * $ratio;
            overflow: visible;

            @include mq('<=md') {
                padding-top: 100%;
                overflow: visible;
            }
        }

        .product-hotspots-stage--rotator-adjuster {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);

            width: 120%;
            padding-top: 120% * $ratio;

            @include mq('<=md') {
                left: 55%;
                right: auto;
                transform: translate(-66%, -50%);

                width: 175%;
                padding-top: 175% * $ratio;
            }

            // Debugging
            //&::after {
            //    @include overlay();
            //    content: '';
            //    background-color: rgba($c--brand-gerteis-light-blue, 0.2);
            //}
        }

        .product-hotspots-stage--rotator-element {
            @include overlay();

            @include mq('>md') {
                cursor: grab;
            }
        }

        .product-hotspots-stage--rotator-image {
            @include overlay();
            pointer-events: none;
        }
    }

    // Facets

    // States
    & {
        // Draggong cursor
        & {
            .product-hotspots-stage--rotator {
                &.product-hotspots-stage--rotator__is-dragging {
                    @include mq('>md') {
                        cursor: grabbing;
                    }

                    .product-hotspots-stage--hotspots {
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

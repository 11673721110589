.breadcrumbs-nav {
    // Vars

    // Support

    // Module
    & {
        position: relative;

        .breadcrumbs-nav--gradient {
            pointer-events: none;
            display: none;
        }

        .breadcrumbs-nav--items {
            overflow: hidden;

            @include mq('<=sm') {
                @include hide-visually();
            }
        }

        .breadcrumbs-nav--items,
        .breadcrumbs-nav--link-wrap {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        .breadcrumbs-nav--item {
            &.breadcrumbs-nav--item__is-last {
                .breadcrumbs-nav--link {
                    padding: 24px 0;

                    @include has-focus(default) {
                        .breadcrumbs-nav--link-inner {
                            @include link--highlight();
                            @include link--highlight-active();
                            color: $c--brand-gerteis-dark-blue-darker;
                            cursor: default;
                        }
                    }
                }
            }
        }

        .breadcrumbs-nav--link {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            transition: color $trs--base;
            line-height: 1;
            white-space: nowrap;
            color: $c--brand-gerteis-dark-blue;

            @include has-focus(true) {
                .breadcrumbs-nav--link-inner {
                    @include link--highlight-half-active();
                    color: $c--brand-gerteis-dark-blue-darker;
                }
            }
        }

        .breadcrumbs-nav--link-inner {
            @include link--highlight();
            @include type--copy-small;
            position: relative;
            display: block;
            margin-top: 2px;
        }

        .breadcrumbs-nav--chevron {
            margin: 2px 4px 0;
            color: $c--brand-gerteis-dark-blue-darker;
        }

        .breadcrumbs-nav--one-up-btn {
            display: block;
            min-width: 0;
            padding: 24px 0;
            color: $c--brand-gerteis-dark-blue;

            @include mq('>sm') {
                display: none;
            }

            @include has-focus(true) {
                color: $c--brand-gerteis-dark-blue-darker;

                .breadcrumbs-nav--one-up-btn-text {
                    @include link--highlight-half-active();
                }
            }
        }

        .breadcrumbs-nav--one-up-btn-wrap {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        .breadcrumbs-nav--one-up-btn-text {
            @include link--highlight();
            display: inline;
        }
    }

    // Facets

    // States
}

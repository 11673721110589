.team-list-group {
    // Vars

    // Support

    // Module
    & {
        .team-list-group--col {
            @include grid--span((ratio: 6/12));

            @include mq('<=sm') {
                @include grid--span((ratio: 12/12));
            }
        }
    }

    // Facets
    &.team-list-group__even {
        &:first-child {
            .team-list-group--col__right {
                @include mq('<=md') {
                    margin-top: 196px;
                }

                @include mq('<=sm') {
                    margin-top: 0;
                }
            }
        }

        .team-list-group--col__right {
            margin-top: 48px;

            @include mq('<=sm') {
                margin-top: 0;
            }
        }
    }

    // States
}

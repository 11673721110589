/* stylelint-disable plugin/selector-bem-pattern */
.item-slider {
    // Vars
    $sz--list-gutter-x: 24px;
    $sz--list-gutter-y: 0;

    // Support

    // Module
    & {
        overflow: visible;

        .item-slider--button {
            z-index: z-index('swiper-button');
            position: absolute;
            cursor: pointer;
            padding: 16px;
            color: $c--brand-gerteis-dark-blue;
            background-color: $c--monochrome-white;
            border-radius: 100%;
            background-size: 25px 25px;

            @include shadow('level-2');

            &.swiper-button-disabled {
                cursor: unset;
                display: none;
                opacity: 0.2;
            }

            &.item-slider--button__prev {
                top: 50%;
                left: 0;
            }

            &.item-slider--button__next {
                top: 50%;
                right: 0;
            }
        }

        .item-slider--slide {
            display: flex;
            flex-flow: column nowrap;
            width: 100% !important; // stylelint-disable-line declaration-no-important
        }

        .swiper-container {
            width: 100%;
            height: 100%;
        }
    }

    // Facets
    & {
        // Gallery / documents
        &.item-slider__gallery {
            .swiper-slide-active {
                @include is-visible(true, $trs--base-speed);
            }

            .swiper-slide-prev,
            .swiper-slide-next {
                @include is-visible(false, $trs--base-speed);
            }
        }

        &.item-slider__documents {
            & {
                @include grid--root((cols: ('slide-inner-col')));
                @include grid--space((gutter: ($sz--list-gutter-y, $sz--list-gutter-x), cols: ('slide-inner-col')));
            }

            .item-slider--slide-inner-col {
                position: relative;
                transform: translateX($sz--list-gutter-x * -0.5);

                &, &::before, &::after {
                    // NOTE: Revert swiper's usage of `box-sizing: content-box` in a parent element
                    box-sizing: border-box;
                }
            }
        }

        &.item-slider__gallery,
        &.item-slider__documents {
            .item-slider--button {
                &.item-slider--button__prev {
                    transform: translate3d(-100%, -50%, 0) translateX(-24px);

                    @include mq('<=md') {
                        transform: translate3d(0, -50%, 0);
                    }
                }

                &.item-slider--button__next {
                    transform: translate3d(100%, -50%, 0) translateX(24px);

                    @include mq('<=md') {
                        transform: translate3d(0, -50%, 0);
                    }
                }
            }
        }
    }

    // States
}

.product-specs {
    // Vars

    $spec-border-style: solid 1px $c--monochrome-cool-gray;
    // Support

    // Module
    & {
        & {
            padding: 24px 0;
            color: $c--monochrome-black;
        }

        .product-specs--col {
            display: flex;
            flex-wrap: wrap;

            @include grid--span((ratio: 3/12));
        }

        .product-specs--title {
            flex: 1 0 auto;
            min-width: 100%;
            margin-bottom: 12px;
        }

        .product-specs--table-wrapper {
            border: $spec-border-style;
            border-radius: 4px;
        }

        .product-specs--table {
            width: 100%;
        }

        .product-specs--table-row {
            border-bottom: $spec-border-style;

            &:last-child {
                border-bottom: none;
            }

            .product-specs--table-cell:first-child {
                border-right: $spec-border-style;
            }
        }

        .product-specs--table-cell {
            line-height: 2.23;
            padding: 0 8px;
        }

        .product-specs--value {
            @include ff--base-semi-bold();
        }
    }

    // Facets
    &.product-specs__card {
        .product-specs--col {
            @include grid--span((ratio: 12/12));
        }
    }

    // States
}

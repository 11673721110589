.form-fieldset {
    // Vars

    // Support

    // Module
    & {
        .form-fieldset--container.form-fieldset--container {
            overflow: visible;
        }

        .form-fieldset--col {
            @include grid--span((ratio: 1/1));
        }

        .form-fieldset--label {
            @include ff--base-semi-bold();
            display: block;
            margin-bottom: 24px;
        }

        .form-fieldset--description {
            @include ff--base-regular();
            display: block;
        }
    }

    // Facets
    & {
        // Base
        &.form-fieldset__base {
            .form-fieldset--row {
                justify-content: center;
            }

            .form-fieldset--col {
                @include grid--span((ratio: 5/12, mq: '>md'));
                @include grid--span((ratio: 6/12, mq: 'md'));
                @include grid--span((ratio: 1/1, mq: '<=sm'));
            }
        }

        // Documents
        &.form-fieldset__documents {
            .form-fieldset--label {
                margin-bottom: 12px;
            }

            .form-fieldset--root {
                padding: 48px 0;
                background-color: $c--monochrome-warm-gray;
            }

            .form-fieldset--row {
                justify-content: center;
            }

            .form-fieldset--col {
                @include grid--span((ratio: 10/12));
                @include grid--span((ratio: 1/1, mq: '<=md'));
                overflow: visible;
            }
        }
    }

    // States
}

.team-list-member-image {
    // Vars

    // Support

    // Module
    & {
        & {
            content: '';
            width: 100%;
            height: 100%;
            border-radius: 100%;
            overflow: hidden;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .team-list-member-image--image {
            width: 100%;
            height: 100%;
        }
    }

    // Facets
    &.team-list-member-image__x-small {
        max-width: 168px;
        max-height: 168px;

        @include mq('<=sm') {
            margin: 0 auto;
        }
    }

    &.team-list-member-image__small {
        max-width: 270px;
        max-height: 270px;

        @include mq('<=sm') {
            margin: 0 auto;
        }
    }

    &.team-list-member-image__medium {
        max-width: 372px;
        max-height: 372px;

        @include mq('<=sm') {
            max-width: 270px;
            max-height: 270px;
            margin: 0 auto;
        }
    }

    &.team-list-member-image__large {
        max-width: 576px;
        max-height: 576px;

        @include mq('<=sm') {
            max-width: 270px;
            max-height: 270px;
            margin: 0 auto;
        }
    }

    // States
}

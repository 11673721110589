/// Field styles
///
@mixin form-field--primary-checkbox($sz--box) {
    // Vars
    $sz--checkbox-icon: 12px;
    $sz--checkbox-border-radius: 4px;

    // Module
    & {
        .form-field--input[type='checkbox'] {
            ~ .form-field--label-wrap {
                .form-field--box {
                    width: $sz--box;
                    height: $sz--box;

                    &,
                    &::after,
                    &::before {
                        border-radius: $sz--checkbox-border-radius;
                    }
                }

                .form-field--box-icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: ($sz--checkbox-icon / -2);
                    margin-top: ($sz--checkbox-icon / -2);
                    width: $sz--checkbox-icon;
                    height: $sz--checkbox-icon;
                }
            }
        }
    }

    // States
    & {
        // Checked
        @include form-field--apply-states(()) {
            .form-field--input {
                @include form-field--bool-apply-states(('checked': true, 'hover': default)) {
                    @include form-field--primary-checkbox-checked();
                }
            }
        }

        // Disabled and checked
        @include form-field--apply-states(()) {
            .form-field--input {
                @include form-field--bool-apply-states(('disabled': true, 'checked': true)) {
                    @include form-field--primary-checkbox-disabled-checked();
                }
            }
        }

        // Error and checked
        @include form-field--apply-states(('error': true)) {
            .form-field--input {
                @include form-field--bool-apply-states(('checked': true)) {
                    @include form-field--primary-checkbox-error-checked();
                }
            }
        }
    }
}

/// Checked
///
@mixin form-field--primary-checkbox-checked() {
    .form-field--box {
        background-color: $c--brand-gerteis-dark-blue-darker;
    }

    .form-field--box::after {
        border-color: $c--brand-gerteis-dark-blue-darker;
    }

    .form-field--box-icon {
        opacity: 1;
        color: $c--monochrome-white;
    }
}

/// Disabled and checked
///
@mixin form-field--primary-checkbox-disabled-checked() {
    .form-field--box {
        background-color: $c--monochrome-cool-gray;
    }

    .form-field--box::after {
        border-color: $c--monochrome-cool-gray;
    }

    .form-field--box-icon {
        color: $c--monochrome-white;
    }
}

/// Error and checked
///
@mixin form-field--primary-checkbox-error-checked() {
    .form-field--box {
        background-color: $c--signal-error;
    }

    .form-field--box::after {
        border-color: $c--signal-error;
    }

    .form-field--box-icon {
        color: $c--monochrome-white;
    }
}

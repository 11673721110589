.overlay {
    // Vars

    // Support

    // Module
    & {
        .overlay--root {
            z-index: z-index('overlay');
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .overlay--backdrop {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba($c--monochrome-black, 0.5);
        }

        .overlay--display {
            @include ios-native-scrolling;
            position: relative;
            width: 100%;
            height: 100%;
        }

        .overlay--backdrop-click-area {
            @include overlay();
            z-index: 1;
        }

        .overlay--wrap-outer {
            @include is-selectable(true);
            display: table;
            width: 100%;
            height: 100%;
            table-layout: fixed;
        }

        .overlay--wrap-inner {
            position: relative;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }

        .overlay--container {
            z-index: 2;
            position: relative;
            width: 100%;
        }

        .overlay--revealer {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            text-align: left;
        }
    }

    // Facets
    & {
        // Mobile nav
        &.overlay__mobile-nav {
            @include overlay(fixed);
            pointer-events: none;
            z-index: z-index('overlay');
            perspective: 1200px;
            perspective-origin: top;

            .overlay--root {
                pointer-events: auto;
                transform-origin: top center;
            }

            .overlay--wrap-inner {
                padding-top: $sz--mobile-nav-height;
            }

            .overlay--display,
            .overlay--wrap-outer,
            .overlay--wrap-inner,
            .overlay--container,
            .overlay--revealer {
                display: flex;
                flex-flow: row nowrap;
                align-items: stretch;
            }

            .overlay--wrap-inner,
            .overlay--container,
            .overlay--revealer {
                flex: 0 1 100%;
            }
        }
    }

    // States
}

.abstract-card {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 12px 0;
        }

        .abstract-card--link {
            display: flex;
            flex-flow: row wrap;
            background-color: $c--monochrome-white;
            border-radius: 4px;
            overflow: hidden;

            @include shadow-animated('level-1');
        }

        .abstract-card--header {
            @include grid--span((ratio: 3/10, mq: '>md'));
            @include grid--span((ratio: 10/10, mq: '<=md'));
            overflow: hidden;
        }

        .abstract-card--content {
            display: flex;
            flex-flow: column nowrap;
            align-items: stretch;
            justify-content: center;
            flex: 1 0 0;

            @include mq('<=lg') {
                flex: 0 1 100%;
            }
        }

        .abstract-card--action-wrapper {
            display: flex;
            flex-flow: column;
            justify-content: center;
            padding: 0 24px;

            @include mq('<=lg') {
                display: none;
            }
        }

        .abstract-card--action {
            color: $c--brand-gerteis-dark-blue;
            transition: color $trs--base;
        }
    }

    // Facets
    &.abstract-card__product {
        .abstract-card--header {
            @include grid--span((ratio: 3/10, mq: '>lg'));
            @include grid--span((ratio: 10/10, mq: '<=lg'));
            overflow: hidden;
        }
    }

    &.abstract-card__event {
        .abstract-card--action-wrapper {
            @include mq('<=lg') {
                display: flex;
            }

            @include grid--span((ratio: 10/10, mq: '<=sm'));
        }

        .abstract-card--content {
            @include grid--span((ratio: 1/2, mq: '<=lg'));
            @include grid--span((ratio: 9/10, mq: '<=md'));
        }

        .abstract-card--action {
            @include mq('<=sm') {
                padding: 8px;
            }
        }
    }

    // States
    & {
        .abstract-card--link {
            @include has-focus(true) {
                @include shadow('level-2');

                .abstract-card--action {
                    color: $c--brand-gerteis-dark-blue-darker;
                }
            }
        }
    }
}

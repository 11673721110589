.product-heading {
    // Vars

    // Support

    // Module
    & {
        .product-heading--title {
            padding-top: 96px;

            @include type--h3();
        }

        .product-heading--row {
            justify-content: center;
        }

        .product-heading--col {
            @include grid--span((ratio: 10/12));
            @include grid--span((ratio: 12/12, mq: '<=sm'));
        }

        .product-heading--content {
            margin: 48px 0;
            color: $c--monochrome-steel-gray;
        }
    }

    // Facets

    // States
}

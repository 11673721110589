/// Field styles
///
@mixin form-field--primary-input($sz--padding-top, $sz--padding-x, $sz--padding-bottom, $sz--border-width-base) {
    // Vars

    // Support

    // Module
    & {
        .form-field--input-container {
            position: relative;
        }

        .form-field--title-label {
            cursor: pointer;
            transition: $trs--common-props;
        }

        .form-field--input {
            display: inline-block;
            width: 100%;
            padding: $sz--padding-top $sz--padding-x $sz--padding-bottom;
            border-width: $sz--border-width-base;
            border-style: solid;
            border-radius: $sz--border-radius-base;
            line-height: 1.3;
            cursor: text;
            transition: transition-props($trs--base, box-shadow, $trs--common-property-list...);

            @include placeholder() {
                transition: $trs--common-props;
            }
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--primary-input-base();
        }

        // Focus
        @include form-field--apply-states(('disabled': false, 'focus': true)) {
            @include form-field--primary-input-focus();
        }

        // Disabled
        @include form-field--apply-states(('disabled': true, 'focus': default)) {
            @include form-field--primary-input-disabled();
        }

        // Error
        @include form-field--apply-states(('error': true, 'focus': default)) {
            @include form-field--primary-input-error();
        }
    }
}

/// Base
///
@mixin form-field--primary-input-base() {
    .form-field--input {
        color: $c--monochrome-slate-gray;
        background-color: $c--monochrome-white;
        border-color: $c--monochrome-cool-gray;

        @include placeholder() {
            color: $c--monochrome-cool-gray;
        }
    }
}

/// Focus
///
@mixin form-field--primary-input-focus() {
    $sz--box-shadow-offset: 6px;

    .form-field--title-label {
        color: $c--brand-gerteis-dark-blue-darker;
    }

    .form-field--input {
        color: $c--brand-gerteis-dark-blue;
        border-color: $c--brand-gerteis-dark-blue-lighter;

        @include shadow('level-1');

        /**
        box-shadow:
            0 ($sz--box-shadow-offset + 4) 20px 0 rgba(0, 0, 0, 0.19),
            0 $sz--box-shadow-offset 6px 0 rgba(0, 0, 0, 0.26);
        */
    }
}

/// Disabled
///
@mixin form-field--primary-input-disabled() {
    .form-field--title-label {
        @include is-selectable(false);
        cursor: default;
    }

    .form-field--input {
        @include is-selectable(false);
        color: $c--monochrome-cool-gray;
        border-color: rgba($c--monochrome-cool-gray, 0.25);
        cursor: default;

        @include placeholder() {
            color: transparent;
        }
    }
}

/// Error
///
@mixin form-field--primary-input-error() {
    .form-field--input {
        border-color: $c--signal-error;
    }

    .form-field--title-label {
        color: $c--signal-error;
    }
}

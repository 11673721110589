.cookie-banner {
    // Vars

    // Support

    // Module
    & {
        & {
            z-index: z-index('cookie-banner');
            position: fixed;
            bottom: 24px;
            width: calc(100% - 48px);
            margin: 0 24px;
            padding: 12px;
            border-radius: 4px;
            background-color: $c--monochrome-white;
            color: $c--monochrome-slate-gray;
            box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.26);
            font-size: $fz--small;

            @include mq('<=md') {
                bottom: 8px;
                padding: 10px 12px;
                margin: 0 8px;
                font-size: $fz--x-small;
                width: calc(100% - 16px);
            }
        }

        .cookie-banner--message-col {
            @include grid--span((ratio: 8 / 12));
            @include grid--span((ratio: 8 / 12, mq: 'lg'));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));
        }

        .cookie-banner--actions-col {
            @include grid--span((ratio: 4 / 12));
            @include grid--span((ratio: 4 / 12, mq: 'lg'));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));

            @include mq('<=sm') {
                margin-top: 10px;
            }

            display: flex;
            justify-content: flex-end;
        }

        .cookie-banner--btn {
            margin-right: 12px;

            &:last-child {
                margin-right: 0;
            }
        }

        .cookie-banner--row {
            justify-content: space-between;
            align-items: center;
        }
    }

    // Facets

    // States
}

.category-label {
    // Vars

    // Support

    // Module
    & {
        & {
            background-color: rgba($c--brand-gerteis-light-blue, 0.2);
            color: $c--brand-gerteis-dark-blue;
            font-size: $fz--xx-small;
            padding: 7px 10px;
            text-transform: uppercase;
        }
    }

    // Facets

    // States
}

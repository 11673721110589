/// Form field facet
///
@mixin form-field--primary() {
    // Vars
    $sz--input-padding-x: 16px;
    $sz--input-padding-top: 16px;
    $sz--input-padding-bottom: $sz--input-padding-top;
    $sz--input-padding-centered: ($sz--input-padding-top + $sz--input-padding-bottom) / 2;

    $sz--input-border-width: 1px;

    $fz--bool-label: $fz--small / 1rem * 10px; // Convert to px
    $lh--bool-label: 1.5;
    $sz--bool-box-gutter: 2.5px;
    $sz--bool-box: ($fz--bool-label + (2 * $sz--bool-box-gutter));
    $sz--bool-box-border: 2px;

    // Support

    // Fields
    & {
        @include form-field--primary-all();

        &.form-field__hidden {
            @include form-field--primary-hidden();
        }

        &.form-field__input {
            @include form-field--primary-input(
                $sz--padding-top: $sz--input-padding-top,
                $sz--padding-x: $sz--input-padding-x,
                $sz--padding-bottom: $sz--input-padding-bottom,
                $sz--border-width-base: $sz--input-border-width
            );

            &.form-field__textarea {
                @include form-field--primary-textarea();
            }

            &.form-field__select {
                @include form-field--primary-select(
                    $sz--padding-x: $sz--input-padding-x,
                    $sz--padding-y: $sz--input-padding-centered
                );
            }
        }

        &.form-field__bool {
            @include form-field--primary-bool(
                $fz--label: $fz--bool-label,
                $lh--label: $lh--bool-label,
                $sz--box: $sz--bool-box,
                $sz--box-border: $sz--bool-box-border
            );

            &.form-field__radio {
                @include form-field--primary-radio(
                    $sz--box: $sz--bool-box
                );
            }

            &.form-field__checkbox {
                @include form-field--primary-checkbox(
                    $sz--box: ($sz--bool-box - 2px)
                );

                &.form-field__document-checkbox-item {
                    @include form-field--primary-document-checkbox-item();
                }
            }
        }
    }

    // Addons
    & {
        &.form-field__floating-label {
            @include form-field--primary--floating-label(
                $sz--padding-top: $sz--input-padding-top,
                $sz--padding-x: $sz--input-padding-x,
                $sz--padding-bottom: $sz--input-padding-bottom,
                $sz--border-width-base: $sz--input-border-width
            );
        }

        &.form-field__has-actions {
            @include form-field--primary-actions(
                $sz--padding-x: $sz--input-padding-x
            );
        }
    }
}

.branch-list {
    // Vars

    // Support

    // Module
    & {
        & {
            border-top: $border-style;
        }

        .branch-list--search {
            padding: 24px 0;
        }

        .branch-list--map {
            background-color: $c--monochrome-warm-gray;
            padding: 24px 0;
        }

        .branch-list--branches {
            background-color: $c--monochrome-warm-gray;
            padding: 24px 0;
        }
    }

    // Facets

    // States
}

.card-header-image {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row;
        width: 100%;
        height: 100%;
        background-color: $c--monochrome-light-gray;

        .card-header-image--image {
            overflow: hidden;
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;

            @include at-root(html, '.ie11') {
                width: auto;
                height: 100%;
            }
        }
    }

    // Facets

    // States
}

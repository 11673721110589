.footer-social-link {
    // Vars

    // Support

    // Module
    & {
        .footer-social-link--link {
            display: block;
            color: $c--brand-gerteis-dark-blue;
            transition: color $trs--base;

            @include has-focus(true) {
                color: $c--brand-gerteis-dark-blue-darker;
            }
        }

        @include mq('<=sm') {
            flex: 0 1 auto;
        }
    }

    // Facets

    // States
}

.team-list-member {
    // Vars

    // Support

    // Module
    & {
        .team-list-member--row {
            justify-content: flex-end;

            @include mq('<=sm') {
                justify-content: center;
            }
        }

        .team-list-member--info-row {
            margin-top: 16px;
        }

        .team-list-member--image-col {
            @include mq('<=sm') {
                @include grid--span((ratio: 6/6));
            }
        }

        .team-list-member--info-col {
            @include mq('<=sm') {
                @include grid--span((ratio: 6/6));
            }
        }
    }

    // Facets
    @import 'facets/team-list-member__large';
    @import 'facets/team-list-member__medium';
    @import 'facets/team-list-member__small';

    // States
}

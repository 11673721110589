.content-video {
    // Vars

    // Support

    // Module
    & {
        .content-video--row {
            justify-content: center;
        }

        .content-video--col {
            @include grid--span((ratio: 10/12, mq: '>md'));
            @include grid--span((ratio: 12/12, mq: '<=md'));
            padding-top: 24px;
            padding-bottom: 24px;
        }

        .content-video--wrapper {
            position: relative;
            padding-top: 9 / 16 * 100%;
        }

        .content-video--iframe {
            @include overlay();
        }

        .content-video--description {
            margin-top: 8px;
            text-align: center;
        }

        .content-video--description-text {
            @include type--copy-small;
            color: $c--monochrome-steel-gray;
        }

        /* stylelint-disable declaration-no-important */
        iframe {
            width: 100% !important;
            height: 100% !important;
        }
        /* stylelint-enable declaration-no-important */
    }


    // Facets

    // States
}

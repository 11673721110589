.tooltip {
    // Vars
    $sz--arrow: 12px;
    $sz--arrow-diag: ceil(($sz--arrow / 1px) * 2 / sqrt(2)) * 1px;
    $sz--border: 0;

    $c--tooltip-bg: $c--monochrome-black;
    $c--tooltip-text: $c--monochrome-white;
    $c--popover-bg: $c--monochrome-white;
    $c--popover-text: $c--monochrome-black;
    $sz--box-shadow-offset-1: 12px;
    $sz--box-shadow-offset-2: 2px;

    // Support
    @function tooltip--rotate-coords($x, $y, $θ) {
        $x2: ($x * cos($θ) - $y * sin($θ));
        $y2: ($x * sin($θ) + $y * cos($θ));
        @return ($x2, $y2);
    }

    // Module
    & {
        z-index: 10000;
        display: block !important; // stylelint-disable declaration-no-important
        color: $c--tooltip-bg;

        .tooltip--wrapper {
            position: relative;
            min-width: 208px;

            &::before,
            &::after {
                @include overlay();
                content: '';
                border-radius: $sz--border-radius-base;
            }

            &::before {
                z-index: 1;
            }

            &::after {
                z-index: 2;
                background-color: $c--tooltip-bg;
            }
        }

        .tooltip--inner {
            color: $c--tooltip-text;
            z-index: 3;
            padding: 5px 10px 4px;
        }

        .tooltip--arrow {
            pointer-events: none;
            z-index: 1;
            position: absolute;
            width: $sz--arrow-diag;
            height: $sz--arrow-diag;

            &::before, &::after {
                content: '';
                position: absolute;
                //top: 0;
                left: 50%;
                display: block;
                width: $sz--arrow;
                height: $sz--arrow;
                background-color: currentColor;
            }

            &::before {
                transform: translate(-50%, -50%) rotateZ(45deg);
            }

            &::after {
                transform: translate(-50%, -50%) rotateZ(45deg) translate(-$sz--border, -$sz--border);
            }
        }
    }

    // Facets
    & {
        &.tooltip__language {
            display: none;

            .tooltip--wrapper {
                min-width: 0;
            }
        }

        &.tooltip__popover {
            color: $c--popover-bg;

            .tooltip--wrapper {
                &::before {
                    box-shadow: (
                        0 $sz--box-shadow-offset-1 28px 0 rgba($c--monochrome-black, 0.25),
                        0 $sz--box-shadow-offset-2 10px 0 rgba($c--monochrome-black, 0.26),
                    );
                }

                &::after {
                    background-color: $c--popover-bg;
                }
            }

            .tooltip--inner {
                color: $c--popover-text;
                padding: 0;
            }

            .tooltip--arrow::before {
                $rotated-coords-1: tooltip--rotate-coords(0, $sz--box-shadow-offset-1, -45deg);
                $rotated-coords-2: tooltip--rotate-coords(0, $sz--box-shadow-offset-2, -45deg);

                box-shadow: (
                    nth($rotated-coords-1, 1) nth($rotated-coords-1, 2) 28px 0 rgba($c--monochrome-black, 0.25),
                    nth($rotated-coords-2, 1) nth($rotated-coords-2, 2) 10px 0 rgba($c--monochrome-black, 0.26),
                );
            }
        }
    }

    // States
    & {
        &[aria-hidden='true'] {
            visibility: hidden;
            opacity: 0;
            transition: opacity 150ms, visibility 150ms;
        }

        &[aria-hidden='false'] {
            visibility: visible;
            opacity: 1;
            transition: opacity 150ms;
        }

        &[x-placement^='top'] {
            margin-bottom: $sz--arrow-diag / 2;
        }

        &[x-placement^='bottom'] {
            margin-top: $sz--arrow-diag / 2;

            .tooltip--arrow {
                top: 0;
            }
        }

        &[x-placement^='right'] {
            margin-left: $sz--arrow-diag / 2;
        }

        &[x-placement^='left'] {
            margin-right: $sz--arrow-diag / 2;
        }
    }
}

.news-list-group {
    // Vars

    // Support

    // Module
    & {
        & {
            overflow: hidden;
        }

        .news-list-group--title {
            margin-bottom: 24px;
        }
    }

    // Facets

    // States
}

.floating-action-button {
    // Vars
    $sz-width: 280px;

    // Support

    // Module
    & {
        .floating-action-button--content-container {
            z-index: z-index('fab');
            position: fixed;
            bottom: 50px;
            display: inline-flex;
            max-width: $sz-width;
            border-radius: 4px;
            width: 76px;
            height: 76px;

            @include mq('<=sm') {
                width: 56px;
                height: 56px;
            }
        }

        .floating-action-button--button.floating-action-button--button {
            color: $c--brand-gerteis-dark-blue;

            @include btn--base;

            @include mq('<=sm') {
                padding: 0;
                border: none;
            }
        }

        .floating-action-button--button__close {
            margin: 0 16px 12px;
        }

        .floating-action-button--icon-link {
            display: inline-flex;
            margin: 12px 16px;
        }

        .floating-action-button--lead {
            color: $c--monochrome-steel-gray;
            margin: 0 16px 16px;

            @include type--copy-medium;
        }

        .floating-action-button--content {
            border-radius: 4px;
            position: relative;
            left: $sz-width * -0.75;
            width: 100%;
            background-color: $c--monochrome-white;

            @include shadow-hover-animated('level-4', 'level-5');
        }

        .floating-action-button--title {
            margin: 16px 16px 0;
        }

        .floating-action-button--button-wrapper {
            @include has-focus(true) {
                .floating-action-button--button {
                    color: $c--brand-gerteis-dark-blue-darker;
                }
            }
        }

        .floating-action-button--button-wrapper {
            width: 100%;
            padding: 16px;
            border-radius: 100%;
            background-color: $c--monochrome-white;
            cursor: pointer;

            @include shadow-hover-animated('level-4', 'level-5');

            @include mq('<=sm') {
                padding: 16px;
            }
        }

        .floating-action-button--content-actions {
            display: flex;
            border-top: $border-style;
            flex-direction: row;
            justify-content: flex-end;
            width: 100%;
            padding-top: 16px;
        }

        .floating-action-button--row {
            justify-content: flex-end;
        }

        .floating-action-button--col {
            @include grid--span((ratio: 1/12));
            @include grid--span((ratio: 1/12, mq: '<=md'));
            @include grid--span((ratio: 2/10, mq: '<=sm'));
            @include grid--span((ratio: 1/4, mq: '<=xs'));
        }
    }

    // Facets
    &.floating-action-button__open {
        .floating-action-button--content-container {
            width: 100%;
            max-width: 288px;

            height: unset;
        }
    }

    &.floating-action-button__open {
        .floating-action-button--button-wrapper {
            display: none;
        }
    }

    &.floating-action-button__cookie-not-accepted {
        .floating-action-button--content-container {
            bottom: 143px;

            @include mq('sm') {
                bottom: 183px;
            }

            @include mq('xs') {
                bottom: 243px;
            }
        }
    }

    // States
}

.news-teaser {
    // Vars

    // Support

    // Module
    & {
        .news-teaser--container {
            padding: 48px 0;
        }

        .news-teaser--col {
            overflow: hidden;
        }

        .news-teaser--row {
            justify-content: center;

            @include mq('<=md') {
                justify-content: flex-start;
            }
        }

        .news-teaser--list {
            margin-bottom: 4.8rem;
        }

        .news-teaser--col {
            @include grid--span((ratio: 10/12));
            @include grid--span((ratio: 12/12, mq:'<=md'));
        }

        .news-teaser--centered-col {
            align-items: center;
            margin-bottom: 19px;

            @include grid--span((ratio: 12/12, mq:'<=md'));
        }

        .news-teaser--button-col {
            display: flex;
            flex-direction: row;
            justify-content: center;

            @include grid--span((ratio: 2/10));
            @include grid--span((ratio: 3/4, mq:'<=md'));
        }

        .news-teaser--button {
            @include mq('<=sm') {
                width: 100%;
            }
        }
    }

    // Facets

    // States
}

.info-popover {
    // Vars

    // Support

    // Module
    & {
        & {
            display: inline-flex;
        }

        .info-popover--toggle {
            color: $c--brand-gerteis-dark-blue;
        }

        .info-popover--popover-inner {
            z-index: z-index('popover');
            max-width: 200px;
            color: $c--monochrome-steel-gray;
            padding: 24px;
        }
    }

    // Facets

    // States
}

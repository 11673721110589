/// The z-index values of elements
///
$layers: (
    content: 0,
    header: 1,
    popover: 3,
    anchornav-inline: 5,
    swiper-button: 6,
    fab: 7,
    anchornav-fixed: 8,
    overlay: 10,
    mobilenav: 12,
    reveal: 12,
    cookie-banner: 14,
);

/// Returns a z-index stored within the `$layers` map, which stores z-indexes of all central ui elements
/// @param {string} $layer - Key that is searched for
/// @return {number} A z-index number
///
@function z-index($layer: 'content') {
    @if not map-has-key($layers, $layer) {
        @error 'The layer `#{$layer}` is not contained within the $layers map!';
    }

    @return map-get($layers, $layer);
}

.document-download {
    // Vars

    // Support

    // Module
    & {
        & {
            margin-bottom: 12px;
            display: flex;
            flex-flow: row;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .document-download--icon {
            display: inline-block;
            color: $c--brand-gerteis-dark-blue;
            margin-top: 4px;
        }

        .document-download--description {
            display: inline-block;
            color: $c--monochrome-steel-gray;
        }
    }

    // Facets

    // States
}

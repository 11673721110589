.map-search {
    // Vars

    // Support

    // Module
    & {
        .map-search--title {
            margin-top: 24px;
        }

        .map-search--description {
            color: $c--monochrome-steel-gray;

            @include type--copy-medium;
        }

        .map-search--container.map-search--container {
            overflow: visible;
        }

        .map-search--container-row {
            justify-content: center;
        }

        .map-search--container-col {
            @include grid--span((ratio: 10/12));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }

        .map-search--search-row {
            margin-top: 24px;
            justify-content: flex-start;
            align-items: flex-end;
        }

        .map-search--button-col {
            @include grid--span((ratio: 1/10));
        }

        .map-search--input-col {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            @include grid--span((ratio: 3/10));
            @include grid--span((ratio: 8/10, mq: 'md'));
            @include grid--span((ratio: 10/10, mq: '<=sm'));
        }

        .map-search--input-help {
            position: relative;
            top: -3px;
            margin-left: 24px;
            display: flex;
            align-items: center;
            height: 52px;

            @include grid--span((ratio: 2/10));
        }
    }

    // Facets

    // States
}

.news-list {
    // Vars

    // Support

    // Module
    & {
        .news-list--group {
            margin-top: 48px;
        }

        .news-list--row {
            justify-content: center;
        }

        .news-list--col {
            @include grid--span((ratio: 10/12));
        }
    }

    // Facets

    // States
}

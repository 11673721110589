.product-spec-table {
    // Vars
    $spec-border-style: solid 1px $c--monochrome-cool-gray;

    // Support

    // Module
    & {
        & {
            color: $c--monochrome-black;
        }

        .product-spec-table--table-wrapper {
            border: $spec-border-style;
            border-radius: 4px;
            display: inline-block;
            min-width: 248px;
            width: 100%;
        }

        .product-spec-table--table {
            width: 100%;
        }

        .product-spec-table--table-row {
            border-bottom: $spec-border-style;

            &:last-child {
                border-bottom: none;
            }

            .product-spec-table--table-cell:first-child {
                border-right: $spec-border-style;
                width: 50%;
            }
        }

        .product-spec-table--table-cell {
            @include type--copy-small;

            line-height: 2.4;
            padding: 0 4px 0 8px;

            @include mq('<=sm') {
                line-height: 2.4;
            }
        }

        .product-spec-table--value {
            @include ff--base-semi-bold();
        }
    }

    // Facets

    // States
}

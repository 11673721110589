.button {
    // Vars

    // Support

    // Module
    & {
        & {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: 48px 0;
        }

        .button--row {
            justify-content: center;

            @include mq('<=sm') {
                justify-content: flex-start;
            }
        }
    }

    // Facets

    // States
}

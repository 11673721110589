.scroll-reveal {
    // Vars

    // Support
    @mixin reveal-visible() {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    @mixin reveal-hidden() {
        transform: translate3d(0, -80px, 0);
        opacity: 0;
    }

    // Module
    & {
        & {
            z-index: z-index('reveal');
            position: fixed;
            transform: translate3d(0, -80px, 0);
            height: 80px;
            width: 100%;
            transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
        }

        &.scroll-reveal__top {
            @include reveal-visible;
            top: 0;
        }

        &.scroll-reveal__pinned {
            @include reveal-visible;
            top: 0;
        }

        &.scroll-reveal__unpinned {
            @include reveal-hidden;
        }
    }

    // Facets

    // States
}

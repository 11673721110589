.team-list-member-info {
    // Vars

    // Support

    // Module
    & {
        .team-list-member-info--name {
            @include type--h3;
        }

        .team-list-member-info--position {
            color: $c--monochrome-slate-gray;

            @include type--copy-medium();
        }

        .team-list-member-info--mail,
        .team-list-member-info--phone {
            font-size: $fz--small;
            color: $c--label-link;
        }

        @include mq('<=sm') {
            text-align: center;
        }
    }

    // Facets

    // States
}

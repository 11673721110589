.cookie-settings {
    // Vars

    // Support

    // Module
    & {
        .cookie-settings--row {
            justify-content: center;
        }

        .cookie-settings--col {
            @include grid--span((ratio: 5/12, mq: '>md'));
            @include grid--span((ratio: 6/12, mq: 'md'));
            @include grid--span((ratio: 1/1, mq: '<=sm'));
        }
    }

    // Facets

    // States
}

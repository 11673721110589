&.team-list-member__medium {
    .team-list-member--image-col {
        @include grid--span((ratio: 6/6));
    }

    .team-list-member--info-col {
        @include grid--span((ratio: 5/6));
    }
}

&.team-list-member__odd.team-list-member__medium {
    .team-list-member--image-col {
        @include grid--span((ratio: 4/6));
        @include grid--span((ratio: 6/6, mq: '<=md'));
    }

    .team-list-member--info-col {
        @include grid--span((ratio: 3/6));
        @include grid--span((ratio: 4/6, mq: '<=md'));
    }
}

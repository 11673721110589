.document-group {
    // Vars
    $sz--list-gutter-x: 24px;
    $sz--list-gutter-y: 0;

    // Support

    // Module
    & {
        @include grid--root((row: 'inner-row'));
        @include grid--space((gutter: ($sz--list-gutter-y, $sz--list-gutter-x), row: 'inner-row'));

        margin-top: 48px;

        .document-group--items-wrapper {
            display: flex;
            flex-flow: row nowrap;
        }

        .document-group--label {
            @include type--copy-medium;
            color: $c--monochrome-steel-gray;
            margin-bottom: 20px;
        }

        .document-group--item-wrapper {
            display: flex;
            flex-flow: row nowrap;
        }

        .document-group--item {
            @for $i from 1 through 10 {
                &[data-items-per-chunk='#{$i}'] {
                    @include grid--span((ratio: 1/$i));
                }
            }
        }
    }

    // Facets

    // States
}

.team-list-column {
    // Vars

    // Support

    // Module
    & {
        .team-list-column--row {
            padding: 48px 0;

            @include mq('<=sm') {
                padding: 24px 0;
            }
        }

        .team-list-column--col {
            width: 100%;
        }
    }

    // Facets
    &.team-list-column__odd.team-list-column__left {
        .team-list-column--row:first-child {
            padding-top: 144px;

            @include mq('<=sm') {
                padding-top: 24px;
            }
        }
    }

    // States
}

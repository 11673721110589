.related-content-item {
    // Vars

    // Support

    // Module
    & {
        & {
            height: 100%;
            padding: 24px 0;
        }

        .related-content-item--container {
            height: 100%;
            background-color: $c--monochrome-white;
            overflow: hidden;

            @include shadow('level-1');
        }

        .related-content-item--container {
            @include shadow-hover-animated('level-1', 'level-2');
        }

        .related-content-item--header {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            border-bottom: 1px solid $c--monochrome-light-gray;
            background-color: $c--monochrome-light-gray;
            height: 153px;
            overflow: hidden;
        }

        .related-content-item--image {
            overflow: hidden;
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;


            @include at-root(html, '.ie11') {
                width: auto;
                height: 100%;
            }
        }

        .related-content-item--content-container {
            flex: 0 0 100%;
            padding: 8px 16px 16px;
        }

        .related-content-item--title {
            color: $c--monochrome-black;

            @include type--copy-medium;
        }

        .related-content-item--category {
            color: $c--brand-gerteis-dark-blue;

            @include type--copy-small;
        }

        .related-content-item--content {
            margin-top: 8px;
            color: $c--monochrome-steel-gray;

            @include type--copy-x-small;
        }
    }

    // Facets
    &.related-content-item__product {
        .related-content-item--image {
            object-fit: contain;

            @include at-root(html, '.ie11') {
                width: auto;
                height: 100%;
            }
        }
    }

    // States
}

.product-images {
    // Vars
    $ratio: 16/9;

    $child-large-size: 2/3;
    $child-small-size: 1/3;

    $gutter: 24px;

    // Support

    // Module
    & {
        & {
            background-color: $c--monochrome-warm-gray;
            padding: 48px 0;
        }

        .product-images--wrap {
            position: relative;
            display: inline-block;
            width: calc(100% + #{$gutter});

            @include mq('<=sm') {
                width: 100%;
            }
        }

        .product-images--image {
            float: left;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            @include mq('<=sm') {
                float: unset;
                margin-bottom: $gutter;
            }
        }

        .product-images--image__large {
            width: calc(100% * #{$child-large-size} - #{$gutter * $child-large-size});
            padding-top: calc((100% * #{$child-large-size} - #{$gutter * $child-large-size}) * 1 / #{$ratio});

            @include mq('<=sm') {
                width: 100%;
                padding-top: 1 / $ratio * 100%;
            }
        }

        .product-images--image__small {
            width: calc(100% * #{$child-small-size} - #{$gutter * $child-small-size});
            padding-top: calc(((100% * #{$child-small-size} - #{$gutter * $child-small-size}) * 1 / #{$ratio}) - #{$gutter / 2});
            margin-bottom: $gutter;
            margin-left: $gutter;

            &:last-child {
                margin-bottom: 0;
            }

            @include mq('<=sm') {
                width: 100%;
                padding-top: 1 / $ratio * 100%;
                margin-left: 0;
            }
        }

        .product-images--title {
            margin-bottom: 8px;
        }

        .product-images--col {
            @include grid--span((ratio: 12/12));
        }
    }

    // Facets

    // States
}

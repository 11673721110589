.page {
    // Vars

    // Support

    // Module
    & {
        margin-top: 80px;
    }

    // Facets

    // States
}

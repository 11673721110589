.call-to-action {
    // Vars
    $sz--background-width: 1180px;

    // Support

    // Module
    & {
        & {
            position: relative;
            width: 100%;
            padding: 160px 0;
            overflow: hidden;
        }

        .call-to-action--content {
            width: 100%;
        }

        .call-to-action--container.call-to-action--container {
            overflow: visible;
        }

        .call-to-action--row {
            justify-content: center;
            overflow: visible;
        }

        .call-to-action--lead {
            color: $c--monochrome-steel-gray;

            @include type--copy-medium;
        }

        .call-to-action--button-wrapper {
            margin-top: 24px;
        }

        .call-to-action--title {
            text-align: center;
        }

        .call-to-action--lead {
            margin-top: 4px;
            text-align: center;
        }

        .call-to-action--buttons {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            margin-top: 24px;
        }

        .call-to-action--background-wrapper {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .call-to-action--background-container.call-to-action--background-container {
            position: relative;
            height: 100%;
            overflow: visible;
        }

        .call-to-action--background {
            position: relative;
            top: 0;
            height: 100%;
            width: 1180px;

            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;

            //@include mq('<=xs') {
            //    left: 0;
            //    top: 25%;
            //}
        }
    }

    // Facets
    & {
        &.call-to-action__image-align-right {
            .call-to-action--background {
                right: 0;

                @include mq('xl') {
                    transform: translateX(45%);
                }

                @include mq(('>md' '<=lg')) {
                    transform: translateX(35%);
                }

                @include mq('md') {
                    transform: translateX(25%);
                }

                @include mq('sm') {
                    transform: translateX(15%);
                }

                @include mq('xs') {
                    transform: translateX(8%);
                }
            }
        }

        &.call-to-action__image-align-left {
            .call-to-action--background {
                left: 0;

                @include mq('xl') {
                    transform: translateX(-40%);
                }

                @include mq(('>md' '<=lg')) {
                    transform: translateX(-50%);
                }

                @include mq(('>xs' '<=md')) {
                    transform: translateX(-60%);
                }

                @include mq('xs') {
                    transform: translateX(-62.5%);
                }
            }
        }
    }

    // States
}

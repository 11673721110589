.footer-menu-branch {
    // Vars

    // Support

    // Module
    & {
        @include grid--span((ratio: 1/6, mq: '>md'));

        .footer-menu-branch--title {
            @include type--copy-x-small();
            @include ff--base-semi-bold();
            padding-bottom: 6px;
            border-bottom: 2px solid $c--monochrome-light-gray;
            margin-bottom: 8px;
        }
    }

    // Facets
    & {
        &.footer-menu-branch__social {
            @include grid--root((row: 'items', cols: ('item')));
            @include grid--space((gutter: 16px, row: 'items', cols: ('item')));
        }
    }

    // States
}

.contact-request-form {
    // Vars

    // Support

    // Module
    & {
        padding-bottom: 96px;
    }

    // Facets

    // States
}

.search-country-list {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 16px;
        }

        .search-country-list--item {
            margin-bottom: 16px;
        }

        .search-country-list--item-text {
            cursor: pointer;

            @include link--highlight();

            @include has-focus(true) {
                @include link--highlight-half-active();
            }
        }

        .search-country-list--item-text__active {
            cursor: pointer;

            @include link--highlight();
            @include link--highlight-half-active();
        }
    }

    // Facets

    // States
}

.lead {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 48px 0;
        }

        .lead--row {
            justify-content: center;
        }

        .lead--col {
            @include grid--span((ratio: 10/12, mq: '>sm'));
        }

        .lead--lead {
            @include type--lead;
        }
    }

    // Facets

    // States
}

/// Global type definitions
///
$fz--large: 2.4rem;
$fz--medium: 1.7rem;
$fz--small: 1.5rem;
$fz--x-small: 1.3rem;
$fz--xx-small: 1.2rem;

$lh--large: 1.5;
$lh--medium: 1.65;
$lh--small: 1.6;
$lh--x-small: 1.6;
$lh--xx-small: 1.6;

/// Headline definitions
///
@mixin type--h1 {
    @include ff--base-regular;
    font-size: 5.6rem;
    line-height: 1.16;
    margin-left: -4px;

    @include mq('<=sm') {
        font-size: 3.2rem;
        line-height: 1.34;
        margin-left: -2px;
    }
}

@mixin type--h2 {
    @include ff--base-regular;
    font-size: 3.2rem;
    line-height: 1.53;

    @include mq('<=sm') {
        font-size: 2.5rem;
        line-height: 1.36;
    }
}

@mixin type--h3 {
    @include ff--base-regular;
    font-size: 1.9rem;
    line-height: 1.42;

    @include mq('<=sm') {
        font-size: 1.9rem;
        line-height: 1.32;
    }
}

@mixin type--h4 {
    @include ff--base-regular;
    font-size: 1.7rem;
    line-height: 1.41;
}

@mixin type--h5 {
    @include ff--base-regular;
    font-size: $fz--medium;
}

@mixin type--h6 {
    @include ff--base-regular;
    font-size: $fz--medium;
}

@mixin type--lead {
    font-size: $fz--large;
    line-height: $lh--large;

    @include mq('<=sm') {
        font-size: 1.9rem;
        line-height: 1.42;
    }
}

@mixin type--copy-medium {
    font-size: $fz--medium;
    line-height: $lh--medium;

    @include mq('<=sm') {
        font-size: $fz--small;
        line-height: $lh--small;
    }
}

@mixin type--copy-small {
    font-size: $fz--small;
    line-height: $lh--small;

    @include mq('<=sm') {
        font-size: $fz--x-small;
        line-height: $lh--x-small;
    }
}

@mixin type--copy-x-small {
    font-size: $fz--x-small;
    line-height: $lh--x-small;
}

@mixin type--hotspot() {
    font-size: 1.4rem;
    line-height: 1.3;

    @include mq('<=md') {
        line-height: 1;
    }
}

/// Basic typographic styling
///
html {
    font-size: 62.5%;
}

body {
    @include ff--base-regular;
    @include type--copy-medium;
    color: $c--label-text;
}

/// Headlines
///
.skel {
    h1:not(.u-reset),
    .u-reset__h1 {
        @include type--h1;
    }

    h2:not(.u-reset),
    .u-reset__h2 {
        @include type--h2;
    }

    h3:not(.u-reset),
    .u-reset__h3 {
        @include type--h3;
    }

    h4:not(.u-reset),
    .u-reset__h4 {
        @include type--h4;
    }

    h5:not(.u-reset),
    .u-reset__h5 {
        @include type--h5;
    }

    h6:not(.u-reset),
    .u-reset__h6 {
        @include type--h6;
    }

    .u-reset__lead {
        @include type--lead;
    }

    /// Tags with visual implication
    ///
    b, strong {
        font-weight: $fw--semi-bold;
    }

    i, em {
        font-style: italic;
    }

    sub {
        font-size: $fz--x-small;
        vertical-align: sub;
    }

    sup {
        font-size: $fz--x-small;
        vertical-align: super;
    }

    /// Simple link
    ///
    a, .u-link {
        color: currentColor;
        text-decoration: none;

        &:not(.u-reset):not(.btn) {
            @include link--highlight();
            cursor: pointer;
            transition: $trs--link-highlight-props;

            @include has-focus(false) {
                color: $c--label-link;
                text-decoration: none;
            }

            @include has-focus(true) {
                @include link--highlight-half-active();
                color: $c--brand-gerteis-dark-blue-darker;
                text-decoration: none;
            }
        }
    }

    /// Misc tags and definitions
    ///
    hr {
        display: block;
        border: 0;
        height: 1px;
        color: inherit; // NOTE: Firefox (v52) needs `color: inherit` to properly display `currentColor`
        background-color: currentColor;
    }

    /// Preformatted text (code)
    ///
    pre, code {
        font-family: monospace;
    }
}

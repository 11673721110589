.accordion-item {
    // Vars

    // Support

    // Module
    & {
        .accordion-item--title-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            @include mq('<=sm') {
                padding: 12px;
            }
        }

        .accordion-item--title.accordion-item--title {
            margin-bottom: 0;
        }

        .accordion-item--col {
            @include grid--span((ratio: 12/12));
        }

        .accordion-item--indicator-col {
            display: flex;
        }

        .accordion-item--content {
            overflow: hidden;
            transition: $trs--base;

            @include mq('<=sm') {
                padding: 0 12px 12px;
            }
        }

        .accordion-item--indicator {
            transition: transform $trs--base;
        }
    }

    // Facets
    &.accordion-item__open {
        .accordion-item--indicator {
            transform-origin: center;
            transform: rotate(180deg);
        }
    }

    // States
}

.product-documents {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 24px 0;
        }

        .product-documents--title {
            margin-bottom: 8px;
        }
    }

    // Facets

    // States
}

.title-lead {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 48px 0;
        }

        .title-lead--title {
            max-width: 978px;
            margin-bottom: 24px;
        }

        .title-lead--lead {
            max-width: 978px;

            @include type--lead();
        }

        .title-lead--row {
            justify-content: center;
        }

        .title-lead--col {
            @include grid--span((ratio: 10/12));

            @include mq('<=md') {
                @include grid--span((ratio: 12/12));
            }
        }
    }

    // Facets

    // States
}

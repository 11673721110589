/// Field styles
///
@mixin form-field--primary-document-checkbox-item() {
    // Vars
    $sz--box: 18px;

    $sz--list-gutter-x: 24px;
    $sz--list-gutter-y: 0;

    // Module
    & {
        @include grid--root((cols: ('input-container')));
        @include grid--space((gutter: ($sz--list-gutter-y, $sz--list-gutter-x), cols: ('input-container')));

        .form-field--label-wrap {
            max-height: unset;
        }

        .form-field--box {
            z-index: 1;
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }

    // States
}

.trs-overlay-fade {
    // Vars
    @if not variable-exists(trs--overlay) {
        $trs--overlay-speed: 200ms !global;
        $trs--overlay-fn: ease('in-out-material') !global;
        $trs--overlay: $trs--overlay-speed $trs--overlay-fn !global;
    }

    // Support

    // Module

    // Facets

    // States
    &-enter-active, &-leave-active {
        transition: transition-props($trs--overlay, opacity);
    }

    &-enter, &-leave-to {
        opacity: 0;
    }
}

.image-gallery {
    // Vars

    // Support

    // Module
    & {
        & {
            margin: 48px 0;
        }

        .image-gallery--item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .image-gallery--row {
            justify-content: center;
        }

        .image-gallery--col {
            @include grid--span((ratio: 10/12, mq: '>md'));
            @include grid--span((ratio: 12/12, mq: '<=md'));
            overflow: visible;
        }

        .image-gallery--label {
            @include type--copy-small;
            margin-top: 16px;
            color: $c--monochrome-steel-gray;
            text-align: center;
        }

        .image-gallery--image {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    // Facets

    // States
}

.menu-button {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        padding: 16px;
        border-top: 1px solid $c--monochrome-light-gray;
    }

    // Facets
    & {
        &.menu-button__accordion {
            justify-content: flex-start;
            border-top: none;
            border-bottom: 1px solid $c--monochrome-light-gray;
            padding-left: 32px;
            margin: 0 16px;
        }
    }

    // States
}

/// Field styles
///
@mixin form-field--primary-all() {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: column nowrap;

        .form-field--input-container {
            position: relative;
            display: flex;
        }

        .form-field--title-label {
            @include ff--base-regular;
            margin-bottom: 4px;
            font-size: $fz--x-small;
            line-height: $lh--x-small;
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--primary-all-base();
        }

        // Required
        @include form-field--apply-states(('required': true)) {
            @include form-field--primary-all-required();
        }
    }
}

/// Base
///
@mixin form-field--primary-all-base() {
    .form-field--title-label {
        color: $c--monochrome-slate-gray;
    }
}

/// Required
///
@mixin form-field--primary-all-required() {
    .form-field--title-label {
        @include form-field--is-required();
    }
}

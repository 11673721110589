.form-field-documents {
    // Vars

    // Support

    // Module
    & {
        .form-field-documents--expand-toggle {
            color: $c--brand-gerteis-dark-blue;
        }

        .form-field-documents--btn {
            cursor: pointer;
        }

        .form-field-documents--expand-toggle-icon {
            position: relative;
            top: 7px;
            display: inline;
        }

        .form-field-documents--row {
            justify-content: center;
        }

        .form-field-documents--col {
            @include grid--span((ratio: 10/12));
        }

        .form-field-documents--slider-col {
            @include grid--span((ratio: 12/12));
        }
    }

    // Facets

    // States
}

.product-card-content {
    // Vars

    // Support

    // Module
    & {
        padding: 48px 24px;

        @include mq('<=lg') {
            padding: 16px;
        }

        .product-card-content--name {
            margin-bottom: 8px;
        }

        .product-card-content--col {
            @include grid--span((ratio: 1/2));
            @include grid--span((ratio: 1/1, mq: '<=lg'));
        }

        .product-card-content--spec-col {
            display: flex;
            flex-flow: column;
            justify-content: center;
        }

        .product-card-content--description {
            font-size: $fz--small;
            color: $c--monochrome-steel-gray;

            //@include mq('<=sm') {
            //    padding: 16px;
            //}
        }

        .product-card-content--spec-table {
            //@include mq('<=sm') {
            //    padding: 0 16px 16px;
            //}
        }

        .product-card-content--name {
            @include mq('<=lg') {
                display: none;
            }
        }
    }

    // Facets

    // States
}

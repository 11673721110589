.form-success {
    // Vars
    $sz: 48px;

    // Support

    // Module
    & {
        padding: 96px 0;
        text-align: center;

        .form-success--icon {
            display: inline-block;
            width: $sz;
            height: $sz;
            margin-bottom: 48px;
            color: $c--signal-success;
        }

        .form-success--title {
            margin-bottom: 24px;
        }
    }

    // Facets

    // States
}

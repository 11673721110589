.search-input {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
            width: 280px;

            @include mq('<=md') {
                width: 100%;
            }
        }

        .search-input--popover {
            width: 100%;
            margin: 0 20px;

            @include mq('<=sm') {
                margin: 0 10px;
            }
        }

        .search-input--input-clear {
            display: inline;
            color: $c--brand-gerteis-dark-blue;
        }

        > span {
            width: 100%;
        }

        .search-input--input-wrapper {
            position: relative;
        }

        .search-input--clear-search {
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translate3d(0, -50%, 0);
            color: $c--brand-gerteis-dark-blue;
        }
    }

    // Facets

    // States
}

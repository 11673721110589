.error-stage {
    // Vars

    // Support

    // Module
    & {
        .error-stage--row {
            justify-content: center;
        }

        .error-stage--col {
            @include grid--span((ratio: 1/2, mq: '>md'));
            @include grid--span((ratio: 1/1, mq: '<=md'));
        }

        .error-stage--content {
            text-align: center;
        }

        .error-stage--title {
            margin-bottom: 24px;
        }

        .error-stage--subtitle {
            margin-bottom: 48px;
        }
    }

    // Facets

    // States
}

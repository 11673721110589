.video-teaser {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
            width: 100%;
            padding-top: 100% / 16 * 9;
            background-size: 0;

            @include grid--root(());

            @include mq('<=sm') {
                background-size: cover;
                background-position: center center;
                width: unset;
                padding: 0;
                height: 568px;
            }
        }


        .video-teaser--video {
            position: absolute;
            top: 0;
            width: 100%;
        }

        .video-teaser--title {
            color: $c--monochrome-white;
            margin-bottom: 8px;
            text-align: center;
        }

        .video-teaser--lead {
            color: $c--monochrome-white;
            margin-bottom: 32px;
            text-align: center;
        }

        .video-teaser--container {
            height: 100%;
        }

        .video-teaser--row {
            position: absolute;
            top: 0;
            justify-content: center;
            width: 100%;
            height: 100%;
        }

        .video-teaser--col {
            height: 100%;

            @include grid--span((ratio: 6/12));
            @include grid--span((ratio: 10/12, mq: '<=sm'));
        }

        .video-teaser--content {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            flex-direction: column;
        }
    }

    // Facets

    // States
}

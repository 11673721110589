.home-link {
    // Vars

    // Support

    // Module
    & {
        display: block;

        .home-link--img {
            display: block;
            height: 44px;


            @include mq('<=lg') {
                height: 32px;
            }
        }
    }

    // Facets

    // States
}

.not-found {
    // Vars

    // Support

    // Module
    & {
        .not-found--content-placeholder {
            padding: 96px 0 48px;
        }
    }

    // Facets

    // States
}

/// Font-styles, usable for project-wide styling of text instances
///
@mixin ff--base-regular($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--normal;
    font-style: normal;
}

@mixin ff--base-italic($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--normal;
    font-style: italic;
}

@mixin ff--base-semi-bold($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--semi-bold;
    font-style: normal;
}

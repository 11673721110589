.lang-nav {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'row', cols: ('col')));
        @include grid--space((gutter: (8px 16px), row: 'row', cols: ('col')));
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .lang-nav--icon {
            margin-right: 8px;
            color: $c--brand-gerteis-dark-blue;
        }

        .lang-nav--items {
            display: flex;
            flex-flow: row wrap;
        }
    }

    // Facets

    // States
}

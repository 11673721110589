$shadows: (
    'level-0': (0 0 0 rgba(0, 0, 0, 0.12), 0 0 0 rgba(0, 0, 0, 0.24)),
    'level-1': (0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)),
    'level-2': (0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)),
    'level-3': (0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)),
    'level-4': (0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)),
    'level-5': (0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22)),
);

@mixin shadow($level) {
    box-shadow: map-get($shadows, $level);
}

@mixin shadow-animated($level) {
    transition: all 300ms cubic-bezier(0.25, 0.8, 0.25, 1);

    @include shadow($level);
}

@mixin shadow-hover-animated($level, $level-focused) {
    transition: box-shadow 300ms cubic-bezier(0.25, 0.8, 0.25, 1);

    @include shadow($level);

    @include has-focus(true) {
        @include shadow($level-focused);
    }
}

/// Inspiration:
/// @link https://codepen.io/eehayman/pen/WoWzWd
///
@mixin link--highlight() {
    transition: background-size 400ms ease, color $trs--base;

    background-image:
        linear-gradient(to bottom, transparent 56%, rgba($c--brand-gerteis-light-blue, 0.125) 0),
        linear-gradient(to bottom, transparent 56%, rgba($c--brand-gerteis-light-blue, 0.075) 0);
    background-repeat: no-repeat, no-repeat;
    background-position: left center, left center;
    background-size: 0 100%, 0 100%;
}

@mixin link--highlight-half-active() {
    background-size: 0 100%, 100% 100%;
}

@mixin link--highlight-active() {
    background-size: 100% 100%, 100% 100%;
}


.footer {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'menu-links-row', cols: ('menu-links-col')));
        @include grid--space((gutter: (24px null), row: 'menu-links-row', cols: ('menu-links-col')));
        padding: 18px 0 0;
        margin-top: 48px;

        @include mq('<=md') {
            border-top: 1px solid $c--monochrome-light-gray;
        }

        .footer--links {
            padding-bottom: 40px;
        }

        .footer--menu-links-col {
            overflow: hidden;
        }
    }

    // Facets
    & {
        &.footer__cookie-not-accepted {
            margin-bottom: 200px;
        }
    }

    // States
}

.product-image {
    // Vars

    // Support

    // Module
    & {
        & {
            background-color: $c--monochrome-warm-gray;
        }

        .product-image--description {
            color: $c--monochrome-steel-gray;
            margin-top: 24px;
            margin-bottom: 40px;
        }

        .product-image--row {
            justify-content: center;
            max-width: 100%;
        }

        .product-image--logo {
            max-width: 100%;
            margin: 40px 0;
            height: 36px;

            @include mq('<=sm') {
                height: unset;
                width: 100%;
            }
        }

        .product-image--image {
            @include mq('<=sm') {
                width: 100%;
                height: auto;
            }
        }

        .product-image--image-col {
            display: flex;
            flex-flow: row;
            justify-content: center;

            @include grid--span((ratio: 6/12));

            @include mq('<=sm') {
                display: block;

                @include grid--span((ratio: 12/12));
            }
        }
    }

    // Facets

    // States
}

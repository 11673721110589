.footer-menu-link {
    // Vars

    // Support

    // Module
    & {
        @include grid--span((ratio: 1/1, mq: '<=md'));

        .footer-menu-link--text {
            @include type--copy-small();
            @include link--highlight();
            color: $c--brand-gerteis-dark-blue;
        }

        .footer-menu-link--link {
            @include has-focus(true) {
                .footer-menu-link--text {
                    @include link--highlight-half-active();
                    color: $c--brand-gerteis-dark-blue-darker;
                }
            }

            &.footer-menu-link--link__is-active {
                @include has-focus(default) {
                    .footer-menu-link--text {
                        @include link--highlight-active();
                        color: $c--brand-gerteis-dark-blue-darker;
                        cursor: default;
                    }
                }
            }
        }
    }

    // Facets
    & {
        @each $product-color-key, $product-color-value in $products {
            &.footer-menu-link__product-#{$product-color-key} {
                .footer-menu-link--text {
                    color: $product-color-value;
                }

                .footer-menu-link--link {
                    @include has-focus(true) {
                        .footer-menu-link--text {
                            color: color-shade($product-color-value, 20%);
                        }
                    }

                    &.u-router-link-exact-active {
                        .footer-menu-link--text {
                            color: color-shade($product-color-value, 20%);
                        }
                    }
                }
            }
        }
    }

    // States
}

.product-ribbon {
    // Vars

    // Support
    @mixin product-ribbon--generate-ribbons($ribbons) {
        @each $facet-name, $ribbon-color in $ribbons {
            .product-ribbon--ribbon__product-#{$facet-name} {
                background-color: $ribbon-color;
            }
        }
    }

    // Module
    & {
        position: absolute;
        top: 0;
        left: 0;
        width: 48px;
        height: 48px;
        overflow: hidden;
    }

    .product-ribbon--ribbon {
        content: '';
        position: absolute;
        top: 50%;
        left: calc(50% - 7px);
        height: 10px;
        width: 100px;
        transform: translate3d(-50%, -50%, 0) rotateZ(-45deg);
    }

    // Facets
    & {
        // Default
        & {
            .product-ribbon--ribbon {
                background-color: $c--brand-gerteis-dark-blue;
            }
        }

        // Products
        & {
            @include product-ribbon--generate-ribbons($products);
        }
    }

    // States
}

.accordion {
    // Vars
    @if not variable-exists(trs--accordion) {
        $trs--accordion-speed: 300ms !global;
        $trs--accordion-fn: ease !global;
        $trs--accordion: $trs--accordion-speed $trs--accordion-fn !global;
    }

    // Support

    // Module
    & {
        .accordion--head {
            display: block;
            width: 100%;
            text-align: left;
            cursor: pointer;
        }

        .accordion--body {
            overflow: hidden;
            max-height: 0;
            opacity: 0;
            transform: rotateZ(1turn);
        }
    }

    // Facets
    & {
        &.accordion__base {
            &.accordion__is-animating {
                .accordion--body {
                    transition: opacity $trs--accordion, max-height $trs--accordion;
                }
            }
        }
    }

    // States
    & {
        &.accordion__is-active {
            .accordion--body {
                opacity: 1;
            }
        }
    }
}

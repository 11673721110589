/// Field styles
///
@mixin form-field--primary-select($sz--padding-x, $sz--padding-y) {
    // Vars

    // Module
    & {
        @include form-field--primary-actions($sz--padding-x);

        .form-field--input {
            @include ff--base-regular;
            padding-top: $sz--padding-y;
            padding-bottom: $sz--padding-y;
            cursor: pointer;
        }

        .form-field--action {
            pointer-events: none;
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--primary-select-base();
        }

        // Focus
        @include form-field--apply-states(('disabled': false, 'focus': true)) {
            @include form-field--primary-select-focus();
        }

        // Disabled
        @include form-field--apply-states(('disabled': true, 'focus': default)) {
            @include form-field--primary-select-disabled();
        }

        // Empty
        @include form-field--apply-states(('filled': false)) {
            @include form-field--primary-select-empty();
        }

        // Empty and disabled
        @include form-field--apply-states(('filled': false, 'disabled': true)) {
            @include form-field--primary-select-empty-disabled();
        }
    }
}

/// Base
///
@mixin form-field--primary-select-base() {
    .form-field--action.form-field--action__arrow {
        color: $c--brand-gerteis-dark-blue;
    }
}

/// Focus
///
@mixin form-field--primary-select-focus() {
    .form-field--action.form-field--action__arrow {
        color: $c--brand-gerteis-dark-blue;
    }
}

/// Disabled
///
@mixin form-field--primary-select-disabled() {
    .form-field--action.form-field--action__arrow {
        color: transparent;
    }
}

/// Empty
///
@mixin form-field--primary-select-empty() {
    .form-field--input {
        color: $c--monochrome-cool-gray;
    }
}

/// Empty and disabled
///
@mixin form-field--primary-select-empty-disabled() {
    .form-field--input {
        color: transparent;
    }
}

.main-nav {
    // Vars
    $sz--spacing-large: (24px 48px);
    $sz--spacing-small: (18px 36px);

    // Support

    // Module
    & {
        & {
            @include grid--space((mq: '>lg', gutter: $sz--spacing-large, row: 'items', cols: ('item')));
            @include grid--space((mq: '<=lg', gutter: $sz--spacing-small, row: 'items', cols: ('item')));
            border-bottom: $border-style;
            background-color: $c--monochrome-white;
        }

        .main-nav--container.main-nav--container {
            padding: 0 32px;

            @include mq('<=lg') {
                max-width: 100%;
                padding: 0 16px;
            }
        }

        .main-nav--bar {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            height: $sz--main-nav-height;
        }

        .main-nav--list {
            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            flex-direction: row;
            justify-content: center;

            width: 100%;
            height: 100%;

            @include mq('<=lg') {
                position: relative;
            }
        }

        .main-nav--items {
            display: flex;
            flex-flow: row nowrap;
        }

        .main-nav--home,
        .main-nav--lang-nav {
            z-index: z-index('header');
        }
    }

    // Facets

    // States
}

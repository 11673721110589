.related-content-list {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 24px 0;
            background-color: $c--monochrome-warm-gray;
        }

        .related-content-list--col {
            overflow: hidden;
        }

        .related-content-list--item {
            @include grid--span((ratio: 3/12));
            @include grid--span((ratio: 6/12, mq: 'md'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));

            &.related-content-list--item__product {
                @include grid--span((ratio: 6/12));
                @include grid--span((ratio: 12/12, mq: '<=md'));
            }
        }
    }

    // Facets

    // States
}

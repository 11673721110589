.icon-link {
    // Vars

    // Support

    // Module
    & {
        & {
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-content: center;
            align-items: center;
        }

        .icon-link--label {
            margin-left: 10px;
        }
    }

    // Facets

    // States
}

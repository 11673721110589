// stylelint-disable selector-max-universal, plugin/selector-bem-pattern

.rich-text-content {
    // Vars

    // Support

    // Module
    & {
        padding: 24px 0;
        color: $c--monochrome-steel-gray;

        h2, h3 {
            color: $c--monochrome-black-gray;
        }

        * {
            + p {
                margin-top: 24px;
            }

            + h1, + h2, + h3, + h4, + h5, + h6 {
                margin-top: 48px;
            }

            + ul, + ol {
                margin-top: 8px;
            }
        }
    }

    // Facets

    // States
}

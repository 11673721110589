.product-detail-columns {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 24px 0;
        }

        .product-detail-columns--row {
            justify-content: flex-end;

            @include mq('<=sm') {
                justify-content: flex-start;
                flex-flow: column;
            }
        }

        .product-detail-columns--wrapper {
            max-width: 567px;
        }

        .product-detail-columns--col {
            @include grid--span((ratio: 6/12));

            &:first-child {
                @include grid--span((ratio: 5/12));
                @include grid--span((ratio: 6/12, mq: '<=md'));
            }

            @include mq('<=sm') {
                @include grid--span((ratio: 12/12));

                &:first-child {
                    @include grid--span((ratio: 12/12));
                }
            }
        }
    }

    // Facets

    // States
}

.news-entry {
    // Vars

    // Support

    // Module
    & {
        & {
            border-bottom: $border-style;
            padding: 14px 0;

            &:first-of-type {
                border-top: $border-style;
            }

            @include grid--root((
                row: 'row',
                cols: ('col'),
            ));

            @include grid--space((
                gutter: 24px,
                row: 'row',
                cols: ('col'),
            ));
        }

        .news-entry--header-col {
            display: flex;
            flex-flow: column;
            justify-content: center;
            overflow: hidden;

            @include grid--span((ratio: 6/10));
            @include grid--span((ratio: 6/10, mq: '<=lg'));
            @include grid--span((ratio: 10/10, mq: '<=md'));
        }

        .news-entry--title {
            color: $c--brand-gerteis-dark-blue;
            display: inline-flex;
            width: fit-content;

            @include link--highlight();

            @include has-focus(true) {
                @include link--highlight-active();
            }

            @include type--h3;
        }

        .news-entry--description {
            margin-top: 4px;
            color: $c--monochrome-steel-gray;

            @include text-ellipsis;

            @include mq('<=md') {
                display: none;
            }
        }

        .news-entry--content--col {
            @include grid--span((ratio: 4/10));
            @include grid--span((ratio: 4/10, mq: '<=lg'));
            @include grid--span((ratio: 10/10, mq: '<=md'));

            @include mq('<=md') {
                margin-top: 16px;
            }
        }

        .news-entry--content-row {
            @include mq('<=md') {
                flex-flow: row-reverse;
                justify-content: flex-end;
            }
        }

        .news-entry--image-wrapper {
            @include grid--span((ratio: 1/2));
        }

        .news-entry--meta-col {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;

            @include grid--span((ratio: 1/2));
        }

        .news-entry--date {
            color: $c--monochrome-steel-gray;
            margin-bottom: 4px;

            @include type--copy-small;

            @include mq('<=md') {
                margin-bottom: 16px;
            }
        }

        .news-entry--image-col {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            padding: 0;

            @include grid--span((ratio: 2/4));
        }

        .news-entry--image {
            display: inline-block;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }

    // Facets

    // States
}

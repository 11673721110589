.anchor-nav {
    // Vars

    // Support

    // Module
    & {
        & {
            z-index: z-index('anchornav-inline');

            position: relative;
            height: 62px;
            border-top: $border-style;
            border-bottom: $border-style;
            background: $c--monochrome-white;
            width: 100%;
            margin-top: 24px;

            @include grid--root();
            @include grid--space();

            @include shadow('level-0');
        }

        .anchor-nav--wrapper {
            z-index: z-index('anchornav-fixed');
            top: 0;
            left: 0;
            width: 100%;
            background-color: $c--monochrome-white;
            transition: transform $trs--base-fn 200ms;
            transform: translate3d(0, 0, 0);
        }

        .anchor-nav--list {
            display: flex;
            flex-flow: row;
            justify-content: flex-start;
            margin-top: 16px;
            padding-bottom: 16px;
            width: auto;
            overflow-x: auto;
            overflow-y: hidden;
            flex-wrap: nowrap;
        }

        .anchor-nav--item {
            margin: 0 24px;
            white-space: nowrap;

            &:hover {
                @include link--highlight-active();
            }

            &:first-child {
                margin-left: 0;
            }
        }

        .anchor-nav--row {
            justify-content: center;
        }

        .anchor-nav--col {
            @include grid--span((ratio: 12/12));
            @include grid--span((ratio: 10/12, mq: '>md'));
        }

        .anchor-nav--link {
            cursor: pointer;

            @include link--highlight();

            @include has-focus(true) {
                @include link--highlight-active();
            }

            &.u-router-link-exact-active {
                @include link--highlight();
                @include link--highlight-active();
            }
        }
    }

    // Facets
    &.anchor-nav__offset.anchor-nav__active {
        .anchor-nav--wrapper {
            transform: translate3d(0, 80px, 0);

            @include mq('<=sm') {
                transform: translate3d(0, 60px, 0);
            }
        }
    }

    &.anchor-nav__active {
        .anchor-nav--wrapper-content {
            @include shadow-hover-animated('level-1', 'level-3');
        }

        .anchor-nav--wrapper {
            position: fixed;
        }
    }
}

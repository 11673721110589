.service-teaser {
    // Vars

    // Support

    // Module
    & {
        & {
            background-color: $c--monochrome-warm-gray;
        }

        .service-teaser--content-wrapper {
            padding: 48px 0;

            background-position: left bottom;
            background-repeat: no-repeat;

            @include mq('<=sm') {
                background-position: right top;
            }
        }

        .service-teaser--col {
            @include grid--span((ratio: 6/12));
            overflow: hidden;

            @include mq('<=sm') {
                @include grid--span((ratio: 12/12));
            }
        }

        .service-teaser--button {
            @include mq('<=sm') {
                width: 100%;
            }
        }

        .service-teaser--button-col {
            @include grid--span((ratio: 3/4, mq: '<=sm'));

            @include mq('<=md') {
                margin: 8px 0;
            }
        }

        .service-teaser--image {
            display: block;
            max-width: 100%;
        }

        .service-teaser--col-content.service-teaser--col-content {
            display: flex;
            flex-flow: column;
            justify-content: center;

            @include mq('>lg') {
                max-width: 576px;
            }
        }

        .service-teaser--title {
            @include type--h2;
            margin-bottom: 8px;

            @include mq('<=sm') {
                margin-top: 24px;
            }
        }

        .service-teaser--lead {
            color: $c--monochrome-steel-gray;
            margin-bottom: 24px;

            @include type--h4;
        }
    }

    // Facets

    // States
}

.anchor-nav-target {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
        }

        .anchor-nav-target--anchor {
            position: absolute;
            top: -82px;
        }
    }

    // Facets

    // States
}

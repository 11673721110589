.footer-bottom-row {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'nav-items', cols: ('nav-item')));
        @include grid--space((gutter: (8px 32px), row: 'nav-items', cols: ('nav-item')));

        @include mq('<=md') {
            padding-bottom: 12px;
        }

        .footer-bottom-row--col {
            display: flex;
            flex-flow: row wrap;
            align-items: center;

            &.footer-bottom-row--col__imprint,
            &.footer-bottom-row--col__meta-nav,
            &.footer-bottom-row--col__lang-nav {
                @include mq('>md') {
                    height: $sz--footer-bottom-row-height;
                }

                @include mq('<=md') {
                    padding-top: 12px;
                }
            }

            &.footer-bottom-row--col__meta-nav,
            &.footer-bottom-row--col__lang-nav {
                @include grid--span((ratio: 3/12, mq: '>md'));
                @include grid--span((ratio: 1/2, mq: 'md'));
                @include grid--span((ratio: 1/1, mq: '<=sm'));
            }

            &.footer-bottom-row--col__hr {
                @include grid--span((ratio: 1/1));
            }

            &.footer-bottom-row--col__imprint {
                @include grid--span((ratio: 6/12, mq: '>md'));
                @include grid--span((ratio: 1/1, mq: '<=md'));
            }

            &.footer-bottom-row--col__lang-nav {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-end;

                @include mq('<=sm') {
                    justify-content: flex-start;
                }
            }
        }

        .footer-bottom-row--hr {
            margin: 0;
            width: 100%;
            color: $c--monochrome-light-gray;

            @include mq('<=md') {
                margin-bottom: 4px;
            }
        }

        .footer-bottom-row--imprint {
            @include type--copy-x-small();
            color: $c--monochrome-steel-gray;
        }

        .footer-bottom-row--nav-link {
            @include has-focus(default) {
                &.u-router-link-exact-active {
                    .footer-bottom-row--nav-link-text {
                        @include link--highlight();
                        @include link--highlight-active();
                        color: $c--brand-gerteis-dark-blue-darker;
                        cursor: default;
                    }
                }
            }

            @include has-focus(true) {
                .footer-bottom-row--nav-link-text {
                    @include link--highlight();
                    @include link--highlight-half-active();
                }
            }
        }

        .footer-bottom-row--nav-link-text {
            @include link--highlight();
            @include type--copy-x-small;
            color: $c--brand-gerteis-dark-blue;
        }
    }

    // Facets

    // States
}

.rich-text {
    // Vars

    // Support

    // Module
    & {
        .rich-text--row {
            justify-content: center;
        }

        .rich-text--col {
            @include grid--span((ratio: 8/12, mq: '>md'));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }
    }

    // Facets
    & {
        &.rich-text__wide {
            .rich-text--col {
                @include grid--span((ratio: 10/12, mq: '>md'));
            }
        }
    }

    // States
}

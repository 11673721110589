.map-branch-list {
    // Vars

    // Support

    // Module
    & {
        .map-branch-list--row {
            justify-content: center;
        }

        .map-branch-list--col {
            @include grid--span((ratio: 10/12));

            @include mq('<=md') {
                @include grid--span((ratio: 12/12));
            }
        }
    }

    // Facets

    // States
}

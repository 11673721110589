// Sizes

/// Border radius
$sz--border-radius-base: 0;
$sz--border-width-base: 2px;

/// main-nav
$sz--main-nav-height: 80px;
$sz--mobile-nav-height: 60px;

/// footer-bottom-row
$sz--footer-bottom-row-height: 60px;

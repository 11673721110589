.product-questions {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
            overflow: hidden;
        }

        .product-questions--container {
            padding: 150px 0;

            @include mq('<=sm') {
                padding: 98px 0;
            }
        }

        .product-questions--row {
            justify-content: flex-start;
        }

        .product-questions--content {
            display: flex;
            flex-flow: column;
            justify-content: center;

            @include grid--span((ratio: 3/12));
            @include grid--span((ratio: 3/12, mq: '<=lg'));
            @include grid--span((ratio: 4/12, mq: '<=md'));

            @include mq('<=sm') {
                align-items: center;

                @include grid--span((ratio: 12/12));
            }
        }

        .product-questions--col {
            display: flex;
            flex-flow: column;
            justify-content: center;

            &.product-questions--col__title {
                @include grid--span((ratio: 5/12));
                @include grid--span((ratio: 5/12, mq: '<=lg'));
                @include grid--span((ratio: 4/12, mq: '<=md'));
            }

            &.product-questions--col__image {
                @include grid--span((ratio: 2/12));
                @include grid--span((ratio: 3/12, mq: '<=lg'));
                @include grid--span((ratio: 4/12, mq: '<=md'));
            }

            @include mq('<=sm') {
                &.product-questions--col {
                    flex-flow: row;
                    margin: 12px 0;
                }

                &.product-questions--col__title {
                    @include grid--span((ratio: 12/12));
                }

                &.product-questions--col__image {
                    @include grid--span((ratio: 12/12));
                }
            }
        }

        .product-questions--background {
            content: '';
            position: absolute;
            top: 0;
            right: 50%;
            height: 100%;

            background-repeat: no-repeat;
            background-size: contain;
            width: 1180px;
            background-position: right center;

            @include mq('<=sm') {
                right: 25%;
            }

            @include mq('<=xs') {
                right: 0;
            }
        }

        .product-questions--meta-info,
        .product-questions--cta-wrapper,
        .product-questions--title,
        .product-questions--image {
            margin: 12px 0;
        }

        .product-questions--title {
            text-align: right;
        }

        .product-questions--meta-info {
            @include type--copy-medium;
        }

        .product-questions--meta-sub {
            color: $c--monochrome-steel-gray;
        }
    }

    // Facets

    &.product-questions__right {
        .product-questions--background {
            left: 50%;
        }
    }

    // States
}
